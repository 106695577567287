import { createSlice } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import { PractitionerType, UserRole } from 'src/config';
import {
  BASIC_IMPORT_MODE,
  DEFAULT_CATCHMENT_VALUE,
  UNLIMITED_CATCHMENT_VALUE,
  VALIDATION_STATUS
} from 'src/constants';
import api, { postCompanyMembers, requestDeleteUser, updateUserByIdRequest } from 'src/utility/api';
import {
  canManageAdmin,
  canManageEmployee,
  promoteToLeader,
  requestAdminPermissionsChange,
  revokeLeaderRole,
  updateAdminPermission
} from 'src/utility/permissions';
import { fetchSpecialtyCounters } from '../specialties/specialtiesSlice';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    list: [],
    currentUser: {}
  },
  reducers: {
    updateUsers: (state, action) => {
      state.list = action.payload;
    },
    updateAdmins: (state, action) => {
      const employees = state.list.filter((user) => user.role === UserRole.employee);
      // add new admins list to state list but keep existing employees
      state.list = [...action.payload, ...employees];
    },
    updateUser: (state, action) => {
      let { id, data } = action.payload;
      if (state?.currentUser?._id === id) {
        state.currentUser = { ...state.currentUser, ...data };
      }
      state.list = state.list.map((u) => (u._id === id ? { ...u, ...data } : u));
    },
    changeCurrentUser: (state, action) => {
      let id = action.payload;
      let user = state.list.find((p) => p._id === id);
      if (user) state.currentUser = user;
    },
    updateCurrentUser: (state, action) => {
      const user = action.payload;
      if (user) state.currentUser = user;
    },
    removeUser: (state, action) => {
      let id = action.payload;
      if (state?.currentUser?._id === id) {
        state.currentUser = {};
      }
      state.list = state.list.filter((u) => u._id !== id);
    }
  }
});

export const { updateUsers, updateAdmins, changeCurrentUser, updateUser, updateCurrentUser, removeUser } =
  usersSlice.actions;

export const updateUserById = (id, data) => async (dispatch) => {
  try {
    const response = await updateUserByIdRequest(id, data);
    // response expected to contain user prop if success, if missing update user with data from request
    const { user } = response;
    if (!user) {
      console.warning('updateUserById API response missing user data');
    }
    dispatch(updateUser({ id, data: user ? user : data }));
    // if updated data contain specialties property (speaker case)
    // trigger specialties counters update as well
    if ('specialties' in data) {
      dispatch(fetchSpecialtyCounters());
    }
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchUsers = (role, status, company) => async (dispatch) => {
  try {
    const params = new URLSearchParams('');
    let query = '/users?';
    if (role) {
      params.set('role', role);
    }
    if (status) {
      params.set('status', status);
    }
    if (company) {
      params.set('company', company);
    }
    const { data } = await api.get(`${query}${params.toString()}`);
    const { users } = data ?? {};
    console.log(
      `fetchUsers:: Fetched ${users.length} users with role: ${role} - status: ${status} - company: ${company}.`
    );
    dispatch(updateUsers(users));
    return users;
  } catch (e) {
    console.error('error fetching users: ', e.message);
  }
};

export const fetchAdmins = (companyId) => async (dispatch) => {
  try {
    const { data } = await api.get(`/users/${companyId}/company-admins`);
    const { users } = data ?? {};
    console.log(`fetchAdmins:: Fetched ${users.length} admin users for company: ${companyId}.`);
    dispatch(updateAdmins(users));
    return users;
  } catch (e) {
    console.error('error fetching admins: ', e.message);
  }
};

/**
 * Performs practitioner approval/update
 * @param {'approve'|'update'} action practitioner action to perform
 * @param {string} id practitioner user Id
 * @param {boolean} isReferent true if practitioner is a referent
 * @param {boolean} unlimitedCatchment true if practitioner has unlimited catchment
 * @param {string} [networkName=''] Name of network if practitioner is referent
 * @param {number} commissionPercentage practitioner commission %
 * @returns {Promise<Object>} API return status object when promise resolves
 */
export const acceptPractitioner =
  (action, id, isReferent, unlimitedCatchment, networkName = '', commissionPercentage) =>
  async (dispatch) => {
    try {
      // no network name change on practitioner update
      if (action === 'update') {
        networkName = '';
      }
      const practitionerType = isReferent ? PractitionerType.referent : PractitionerType.default;
      const catchmentArea = unlimitedCatchment ? UNLIMITED_CATCHMENT_VALUE : DEFAULT_CATCHMENT_VALUE;
      await api.post('/users/accept-practitioner', {
        action,
        userId: id,
        practitionerType,
        catchmentArea,
        networkName,
        commissionPercentage
      });
      dispatch(
        updateUser({
          id,
          data: {
            status: VALIDATION_STATUS.activated,
            network: true,
            practitionerType,
            catchmentArea,
            networkName,
            commissionPercentage,
            refusalReason: undefined
          }
        })
      );
      return { status: 'success' };
    } catch (e) {
      console.error('error accepting practitioner: ', e.message);
      return { status: 'error' };
    }
  };

export const refusePractitioner = (id, reason) => async (dispatch) => {
  try {
    await api.post('/users/refuse-practitioner', { userId: id, reason });
    // when practitioner gets refused which can happen after prior approval, reset type to default
    dispatch(
      updateUser({
        id,
        data: { status: VALIDATION_STATUS.refused, practitionerType: PractitionerType.default, refusalReason: reason }
      })
    );
    return { status: 'success' };
  } catch (e) {
    console.error('exception while refusing practitioner: ', e.message);
    return { status: 'error' };
  }
};

export const acceptSpeaker = (id) => async (dispatch) => {
  try {
    await api.post('/users/accept-speaker', { userId: id });
    dispatch(
      updateUser({
        id,
        data: { status: VALIDATION_STATUS.activated, refusalReason: undefined }
      })
    );
    return { status: 'success' };
  } catch (e) {
    console.error('exception while accepting speaker: ', e.message);
    return { status: 'error' };
  }
};

export const refuseSpeaker = (id, reason) => async (dispatch) => {
  try {
    await api.post('/users/refuse-speaker', { userId: id, reason });
    dispatch(
      updateUser({
        id,
        data: { status: VALIDATION_STATUS.refused, refusalReason: reason }
      })
    );
    return { status: 'success' };
  } catch (e) {
    console.error('exception while refusing speaker: ', e.message);
    return { status: 'error' };
  }
};

/**
 * requests user deletion
 * @param {string} userId - The Id of user to delete
 * @param {boolean} [isDeletedAsDuplicate=false] - if true indicates that this user was detected as a duplicate of another user account
 * @returns {Promise<Object>}
 */
export const deleteUser =
  (id, isDeletedAsDuplicate = false) =>
  async (dispatch) => {
    try {
      await requestDeleteUser(id, isDeletedAsDuplicate);
      dispatch(removeUser(id));
      return { status: 'success' };
    } catch (e) {
      console.error('error deleting user: ', e?.response?.data?.message || e.message);
      return { status: 'error', errorCode: e?.response?.data.errorCode };
    }
  };

/**
 * revokes a user admin role and switches him back to employee role if user had an active account
 * or simply delete user account if never validated
 * @param {Object} caller User requesting promotion
 * @param {Object} user user to update
 * @returns {Promise<Object>} API return status object when promise resolves
 */
export const revokeAdminUser = (caller, user) => async (dispatch) => {
  if (canManageAdmin(caller)) {
    try {
      console.log(`revoking Admin role from user: ${user?._id}`);
      const ret = await api.delete(`/users/${user?._id}/revoke-admin`);
      dispatch(fetchUsers());
      if (ret?.data?.status === 'success') {
        console.log(`revoked admin rights from user: ${user?._id}`);
        return { status: 'success' };
      } else {
        console.error(`Failed to revoke Admin role from user: ${user?._id}`);
      }
    } catch (e) {
      console.error(`error revoking admin rights from user: ${user?._id} error:${e.message}`);
    }
  } else {
    console.error(`revokeAdminUser: Error caller (Id ${caller?._id}) is not allowed to perform action`);
  }
  return { status: 'error' };
};

/**
 * promotes a user to Admin role
 * @param {Object} caller User requesting promotion
 * @param {Object} user user to update
 * @returns {Promise<Object>} API return status object when promise resolves
 */
export const promoteUserToAdmin = (caller, user) => async (dispatch) => {
  if (canManageEmployee(caller)) {
    try {
      console.log(`promoting user: ${user?._id} to Admin`);
      const ret = await api.put(`/users/${user?._id}/promote-admin`);
      dispatch(fetchUsers());
      if (ret?.data?.status === 'success') {
        console.log(`updated user: ${user?._id} role to Admin`);
        return { status: 'success' };
      } else {
        console.error(`Failed to update user: ${user?._id} to Admin role`);
      }
    } catch (e) {
      console.error(`error updating to Admin role for user: ${user?._id} error:${e.message}`);
    }
  } else {
    console.error(`promoteUserToAdmin: Error caller (Id ${caller?._id}) is not allowed to perform action`);
  }
  return { status: 'error' };
};

/**
 * add sites delegation to user
 * if not already the case this also promotes the user to admin
 * the caller must have Admins mgt permission
 * @param {Object} caller - User requesting delegation update
 * @param {Object} user - user to update
 * @param {string[]} sites - object containing array of site Ids
 * @returns {Promise<Object>} API return status object when promise resolves
 */
export const delegateSiteMgt = (caller, user, sites) => async (dispatch) => {
  let error = '';
  if (canManageAdmin(caller)) {
    const { _id: userId, company } = user ?? {};
    if (userId) {
      try {
        console.log(`updating site delegation for user: ${userId}`);
        const { _id: companyId } = company;
        const ret = await api.put(`/users/${userId}/site-rights`, sites);
        // only fetch admins as user might not have employee mgt permission
        dispatch(fetchAdmins(companyId));
        if (ret?.data?.status === 'success') {
          console.log(`updated site delegation rights for user: ${userId}`);
          return { status: 'success' };
        } else {
          error = `Failed to update site delegation rights for user: ${userId}`;
        }
      } catch (e) {
        error = `exception while updating site delegation rights for user: ${userId} error:${e.message}`;
      }
    } else {
      error = 'No user Id provided!';
    }
  } else {
    error = `caller (Id: ${caller?._id}) is not allowed to perform action`;
  }
  console.error(`delegateSiteMgt: ${error}`);
  return { status: 'error', error };
};

/**
 * promotes user to leader role
 * @param {Object} caller User requesting promotion
 * @param {Object} user user to update
 * @returns {Promise<Object>} API return status object when promise resolves
 */
export const promoteUserToLeader = (caller, user) => async (dispatch) => {
  try {
    console.log(`promoting user: ${user?._id} to leader`);
    const ret = await promoteToLeader(caller, user);
    dispatch(fetchUsers());
    if (ret) {
      console.log(`updated user: ${user?._id} role to Leader`);
      return { status: 'success' };
    } else {
      console.error(`Failed to update user: ${user?._id} to Leader role`);
    }
    return ret ? { status: 'success' } : { status: 'error' };
  } catch (e) {
    console.error(`error promoting user: ${user?._id} to leader role, error:${e.message}`);
    return { status: 'error' };
  }
};

/**
 * revokes leader role for user
 * @param {Object} caller User requesting revoke action
 * @param {Object} user user to update
 * @returns {Promise<Object>} API return status object when promise resolves
 */
export const revokeLeaderUser = (caller, user) => async (dispatch) => {
  try {
    console.log(`revoking leader role from user: ${user?._id}`);
    const ret = await revokeLeaderRole(caller, user);
    dispatch(fetchUsers());
    if (ret) {
      console.log(`revoked Leader role from user: ${user?._id}`);
      return { status: 'success' };
    } else {
      console.error(`Failed to revoke Leader role from user: ${user?._id}`);
    }
  } catch (e) {
    console.error(`error revoking leader role from user: ${user?._id}, error:${e.message}`);
  }
  return { status: 'error' };
};

/**
 * Adds one or many employee or a company admin
 * @param {Object|Object[]} userData Data of user(s) to add
 * @param {string} role role of user to add (admin or employee)
 * @param {number} [importMode=BASIC_IMPORT_MODE]  only in case of multiple users import - defines import mode
 * @param {string} [company]  optional target company Id when called from VB Admin dashboard
 * @returns {Promise<Object>} API return status object + created count and updated count when promise resolves
 */
export const addCompanyMember =
  (userData, role = UserRole.employee, importMode = BASIC_IMPORT_MODE, company) =>
  async (dispatch, getState) => {
    try {
      if (![UserRole.employee, UserRole.adminCompany].includes(role)) {
        console.log(`usersSlice.js/addCompanyMember:: error invalid role: ${role}`);
        return { status: 'error', error: 'Invalid user role' };
      }
      if (isArray(userData)) {
        console.log(`usersSlice.js/addCompanyMember:: importing users with mode: ${importMode}`);
      }
      const companyId = company ? company : getState().auth?.user?.company?._id;
      console.log(`usersSlice.js/addCompanyMember:: company Id: ${companyId}`);
      const ret = await postCompanyMembers(userData, companyId, role, importMode);
      const {
        data: { addedUserCount, updatedUserCount, deletedUserCount, invalidUserCount }
      } = ret;
      dispatch(role === UserRole.adminCompany ? fetchAdmins(companyId) : fetchUsers());
      return { status: 'success', addedUserCount, updatedUserCount, deletedUserCount, invalidUserCount };
    } catch (e) {
      console.error('usersSlice.js/addCompanyMember | Error while adding company member(s): ', e.message);
      return { status: 'error', error: e?.response?.data?.errors || e.message };
    }
  };

export const fetchProviders = () => async (dispatch, getState) => {
  try {
    const referentId = getState().auth?.user?._id;
    const { data } = await api.get(`/users/${referentId}/get-providers`);
    const { providers } = data ?? {};
    console.log(`Adding ${providers.length} provider(s) to the state`);
    if (providers) {
      dispatch(updateUsers(providers));
    }
  } catch (e) {
    return { status: 'error', error: e.message };
  }
};

/**
 * changes company admin permission
 * @param {Object} caller User requesting permission change
 * @param {Object} user Admin user to update
 * @param {number} permission permission to set (single permission)
 * @param {boolean} [set=true] action to perform, if true (default) sets the permission, otherwise resets permission
 * @returns {Promise<Object>} API return status object when promise resolves
 */
export const changeAdminPermission =
  (caller, user, permission, set = true) =>
  async (dispatch) => {
    try {
      console.log(`Updating permission ${permission} for Admin Id: ${user?._id}`);
      const ret = await updateAdminPermission(caller, user, permission, set);
      if (ret) {
        dispatch(updateUser({ id: user?._id, data: { permissions: user?.permissions | permission } }));
      }
      return ret ? { status: 'success' } : { status: 'error' };
    } catch (e) {
      console.error(`error updating permissions for Admin Id: ${user?._id} error:${e.message}`);
      return { status: 'error' };
    }
  };

/**
 * change company admin permissions
 * @param {Object} caller User requesting permissions change
 * @param {Object} user Admin user to update
 * @param {number} permissions new permissions prop value
 * @returns {Promise<Object>} API return status object when promise resolves
 */
export const changeAdminPermissions =
  (caller, user, permissions = 0) =>
  async (dispatch) => {
    try {
      console.log(`Updating permissions for Admin Id: ${user?._id} - New permissions value: ${permissions}`);
      const ret = await requestAdminPermissionsChange(caller, user, permissions);
      if (ret) {
        dispatch(updateUser({ id: user?._id, data: { permissions } }));
      }
      return ret ? { status: 'success' } : { status: 'error' };
    } catch (e) {
      console.error(`error updating permissions for Admin Id: ${user?._id} error:${e.message}`);
      return { status: 'error' };
    }
  };

export const searchUser = (searchData) => async () => {
  try {
    const { data } = await api.post(`/users/search`, searchData);
    const { users = [], status } = data ?? {};
    if (status === 'success') {
      console.log(`searchUser:: Found ${users.length} user(s) matching search query.`);
    } else {
      console.warn('searchUser:: request failed!');
    }
    return data;
  } catch (e) {
    console.error('searchUser:: error while searching for user(s): ', e.message);
    return { status: 'error', message: e.message };
  }
};

export default usersSlice.reducer;
