import { isBefore } from 'date-fns';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import { EVENT_FILTER, EVENT_STATUS } from 'src/constants';
import { orderEventsByDate } from 'src/utility/sort';
import { THEME_COLORS } from '../config/theme';
import { isGroupSessionEventSpecialty } from '../features/specialties/specialtiesUtils';
import { parseEventDate } from '../utility/parseEventDate';
import { getReviewForEvent } from './api';

const MAX_RESERVED_SLOTS_PERCENTAGE = 20;
const MAX_RESERVED_CONTIGUOUS_SLOTS = 2;

/**
 * checks if event reserved slots match validity constraints:
 * - event cannot have ALL slots marked as reserved / Error = 1
 * - reserved slots count cannot exceed MAX_RESERVED_SLOTS_PERCENTAGE % of total slots available / Error = 2
 * - reserved slots allocation cannot have more than MAX_RESERVED_CONTIGUOUS_SLOTS slots in a row (contiguous) / Error = 3
 * @param {Object[]} slots - event slots
 * @returns {number} 0 if constraints verified, error code as defined above otherwise
 */
export const checkSlotsConstraints = (slots = []) => {
  const reservedCount = slots.reduce((acc, slot) => (slot?.reserved ? acc + 1 : acc), 0);
  if (reservedCount <= 1) {
    // always allowed to add one reserved slot
    return 0;
  }
  const count = slots.length;
  if (reservedCount === count) {
    console.error(`Event slots fail constraint validation! all slots reserved`);
    return 1;
  }
  const percentage = Math.round((reservedCount / count) * 100);
  if (percentage > MAX_RESERVED_SLOTS_PERCENTAGE) {
    console.error(`Event slots fail constraint validation! ${percentage}% reserved slots allocation (>20%)`);
    return 2;
  }
  const contiguousMaxAllocation = slots.reduce(
    (acc, slot) => {
      let { max, cur } = acc;
      if (slot.reserved) {
        cur++;
      } else {
        cur = 0;
      }
      if (cur > max) {
        max = cur;
      }
      return { max, cur };
    },
    { max: 0, cur: 0 }
  );
  if (contiguousMaxAllocation.max > MAX_RESERVED_CONTIGUOUS_SLOTS) {
    console.error(
      `Event slots fail constraint validation! found ${contiguousMaxAllocation}% contiguous reserved slots (max=${MAX_RESERVED_CONTIGUOUS_SLOTS})`
    );
    return 3;
  }
  return 0;
};

/**
 * returns error message for events reserved slots validation error code
 * @param {number} code - error code returned by reserved slots validation check
 * @param {object[]} slots - event slots
 * @returns {string} the error message if any or ""
 */
export const getReservedSlotsError = (code, slots) => {
  switch (code) {
    case 1:
      return t('reserved.slots.all.error');
    case 2:
      const max = Math.floor((slots.length / 100) * MAX_RESERVED_SLOTS_PERCENTAGE);
      return t('reserved.slots.percentage.exceeded.error', { count: max });
    case 3:
      return t('reserved.slots.contiguous.error', { max: MAX_RESERVED_CONTIGUOUS_SLOTS });
    case 0:
    default:
      return '';
  }
};

/**
 * Fetches Event feedback rating data for event identified by its Id
 * if successful sets the feedback notation [0..5] for this event
 * @param {String} eventId - Event Id
 * @param {Callback} setFeedback - callback to trigger when feedback data available (or error)
 * @param {String} [caller=''] - name of caller function
 */
export const fetchEventFeedback = (eventId, setFeedback, caller = '') => {
  const errorState = (msg) => {
    console.error(`${caller}|getReviewForEvent:: event: ${eventId} - ${msg}`);
    setFeedback({});
  };

  console.log(`${caller}|getReviewForEvent:: Fetching feedback data for event: Id='${eventId}'...`);
  getReviewForEvent(eventId)
    .then((res) => {
      const { data } = res ?? {};
      if (data) {
        const { status, eventRating, message = 'Not provided' } = data;
        if (status === 'success') {
          if (eventRating) {
            console.log(
              `${caller}|getReviewForEvent:: feedback data for event: Id=${eventId} - total=${JSON.stringify(
                eventRating
              )}`
            );
            setFeedback(eventRating);
          } else {
            errorState('Error: No event rating data');
          }
        } else {
          errorState(`Error: message: ${message}`);
        }
      } else {
        errorState('Error: API returned NO data');
      }
    })
    .catch((e) => {
      errorState(`Exception: message: ${e.message}`);
    });
};

/**
 * checks if an event has user feedback
 * requires event to be :
 * - registered
 * - past
 * feedback presence determined by event hasFeedback flag (presence and true)
 * @param {Object} event - event to check
 * @returns {boolean} true if event can have feedback
 */
export const eventHasFeedback = (event) => {
  const { status, dateStart, hasFeedback = false } = event;
  if (status === 'registered') {
    const today = new Date();
    const startDay = moment(today).tz('Europe/Paris').startOf('day').utc().format();
    if (moment(startDay).isAfter(dateStart)) {
      // as long as event is past and registered, the event hasFeedback presence
      // is enough to determine feedback presence
      return hasFeedback;
    }
  }
  return false;
};

/**
 * returns list of active appointments from event
 * active means the associated slot is currently booked by a beneficiary
 * @param {Object} event - event to check
 * @returns {Object[]} list of active appointments
 */
export const eventActiveAppointments = (event) => {
  const { appointments = [], slots = [], _id: id } = event ?? {};
  const fromAppointments = appointments.filter((appointment) => appointment?.employee !== null);
  const fromSlots = slots.filter((slot) => slot.booked);
  if (fromAppointments.length !== fromSlots.length) {
    console.warn(`discrepancy in booked slots & appointments for event Id: ${id}`);
  }
  return fromAppointments;
};

/**
 * returns list of group session event attendees
 * @param {Object} event - event to check
 * @returns {Object[]} list of event attendees
 */
export const eventAttendees = (event) => {
  // TODO: build array of attendees for group session event, maybe add check that called on group session event specialty
  return event?.attendees ?? [];
};

/**
 * Normalizes event location string
 * @param {string} location - location as entered by user
 * @returns {string} normalized location string
 */
export const eventLocationNormalize = (location) => {
  const room = t('event.room.label');
  // if location entered by user starts with salle or Salle, remove it
  // as 'Salle :' string is automatically prepended at display
  if (location.startsWith(room) || location.startsWith(capitalize(room))) {
    return location.slice(room.length).trim();
  }
  return location.trim();
};

/**
 * checks if event has a beneficiary waiting list
 * @param {Object} event - event to check
 * @returns {boolean} true if event has a waiting list
 */
export const eventHasWaitingList = (event) => {
  const { eventNotFullSubscribers = [] } = event ?? {};
  return !!eventNotFullSubscribers.length;
};

/**
 * returns the number of beneficiaries in waiting list if event has a waiting list
 * zero otherwise
 * @param {Object} event - event to check
 * @returns {number} the number of beneficiaries in the waiting list
 */
export const eventWaitingListSize = (event) => {
  return eventHasWaitingList(event) ? event.eventNotFullSubscribers.length : 0;
};

/**
 * Get the number of slots available for employees (all slots - reserved ones)
 * @param {Object} event Appointments event
 * @returns the number of slots available for employees
 */
export const getAppointmentsAndAvailableSlots = (event) => {
  const nbAppointments = (event?.appointments?.filter((appointment) => !!appointment.employee) || []).length;
  return {
    nbSlots: event?.slots ? event.slots.filter((slot) => !slot?.reserved).length : 0,
    nbAppointments
  };
};

/**
 * checks if event can be considered as full, which means:
 * - for consultation based events: all available slots booked
 * - for group session events: all seats are registered by beneficiaries
 * @param {Object} event - event to check
 * @returns {boolean} true if event considered as full
 */
export const isEventFull = (event) => {
  const { specialty, attendees = [], seats } = event ?? {};
  const groupSessionEvent = isGroupSessionEventSpecialty(specialty);
  if (groupSessionEvent) {
    // group session events
    return attendees.length === seats;
  }
  // consultation based events
  const { nbSlots, nbAppointments } = getAppointmentsAndAvailableSlots(event);
  const value = nbSlots ? Math.round((nbAppointments / nbSlots) * 100) : 0; // Division by 0 if nbSlots === 0
  return value >= 100;
};

/**
 * checks if event has NO appointments (no available slots booked)
 * @param {Object} event - event to check
 * @returns {boolean} true if event has no appointment
 */
export const isNoBookingEvent = (event) => {
  const { nbAppointments } = getAppointmentsAndAvailableSlots(event);
  return nbAppointments === 0;
};

/**
 * checks if event occurs today and is no ended ie event end time is now past
 * if consultation based event, to check end time we use end time of last slot defined
 * otherwise (group session event) we take event end time
 * @param {Object} event - event to check
 * @returns {boolean} true if event is today and now ended
 */
export const isEventTodayAndEnded = (event) => {
  if (event) {
    const { dateStart, slots, groupSessionEvent, timeEnd } = event;
    const now = moment().tz('Europe/Paris').utc().format();
    if (moment(now).isSame(dateStart, 'day')) {
      // event is today, check end time
      let endTime;
      if (groupSessionEvent) {
        endTime = timeEnd;
      } else {
        // get end time of last slot...
        endTime = slots[slots.length - 1].to;
      }
      const end = endTime.split(':');
      const endTimeDate = moment().tz('Europe/Paris');
      endTimeDate.hour(parseInt(end[0], 10)).minute(parseInt(end[1], 10)).utc().format();
      return moment(now).isAfter(endTimeDate);
    }
  }
  return false;
};

/**
 * checks if event has no associated event token
 * if the company which scheduled the event is in unlimited event mode, always returns false
 * @param {Object} event - event to check
 * @param {Object} company - company which created the event
 * @returns {boolean} true is event has no token (and unlimited event mode is false)
 */
export const isEventWithNoTokenUsed = (event, company) => {
  const { eventTokenUsed } = event ?? {};
  const { options: { unlimitedEvents = false } = {} } = company ?? {};
  return !unlimitedEvents && eventTokenUsed !== true;
};

/**
 * Converts a duration in minutes to object with hours & minutes properties
 * Example : 150 => "2h30"
 * @function
 * @param {number} d duration in minutes
 * @returns {Object} object with hours and minutes properties
 */
export const hoursMinutesFromDuration = (d) => {
  const hours = Math.floor(d / 60);
  const minutes = d % 60;
  return {
    hours,
    minutes
  };
};

/**
 * Converts a duration in minutes to "HhMM" format
 * Example : 150 => "2h30"
 * @function
 * @param {number} d duration in minutes
 * @returns {string} "HhMM" format for given number of minutes
 */
export const minutesToTimeFormat = (d) => {
  const { hours, minutes } = hoursMinutesFromDuration(d);
  return `${hours}h${String(minutes).padStart(2, '0')}`;
};

/**
 * returns event card header background color depending on event type:
 * group session event have different background color than consultation based events
 * @function
 * @param {Object} event - event to get background color for
 * @param {boolean} [forceDefault=false] - if true return default color even if group session event
 * @returns {string} the event card header background color
 */
export const getEventCardBackground = (event, forceDefault = false) => {
  if (forceDefault) {
    return THEME_COLORS.brand;
  }
  const { groupSessionEvent } = event ?? {};
  return groupSessionEvent ? THEME_COLORS.groupSessionEvent : THEME_COLORS.brand;
};

/**
 * computes group session event seats stats
 * returns an object with following properties:
 * - total : total number of seats for this event
 * - registered: number of seats booked
 * - available: number of remaining (available) seats
 * @param {Object} event - event to get available seats count for
 * @returns {Object} event seats stats
 */
export const groupSessionEventSeatStats = (event) => {
  const { specialty, attendees = [], seats } = event ?? {};
  const groupSessionEvent = isGroupSessionEventSpecialty(specialty);
  if (groupSessionEvent) {
    return {
      total: seats,
      registered: attendees.length,
      available: seats - attendees.length
    };
  }
  console.warning('groupSessionEventSeatStats: event provided is not a group session event');
  return {};
};

/**
 * checks if provided date with given time is past now
 * @param {string} date
 * @param {string} time
 * @returns {boolean} true if date+time is past now
 */
export const isPast = (date, time) => {
  const now = new Date();
  return isBefore(new Date(parseEventDate(date, time)), now);
};

/**
 * checks if event is past
 * ie either event start date is in the past (before today)
 * or event start date is today and:
 *  - if group session specialty event => event end time is past now
 *  - if appointment based event => end time of last slot that is available or booked is past now
 *    => reserved slots are not considered as valid time if at end of day
 * @param {Object} event - event to check
 * @returns {boolean} true if event is to consider as past now (time of function call)
 */
export const isEventPast = (event) => {
  if (event) {
    const { groupSessionEvent = false, dateStart, timeEnd, slots = [] } = event;
    if (groupSessionEvent) {
      return isPast(dateStart, timeEnd);
    } else {
      let eventEndTime = timeEnd;
      if (slots.length) {
        // if event slots defined, end time is based on last 'valid' slot end time
        // slots expected to be ordered by time
        // find last slot that is either available for booking or booked, ignore reserved ones
        const notReservedSlots = slots.filter((slot) => !slot.reserved);
        const lastSlot = notReservedSlots.pop();
        eventEndTime = lastSlot.to;
      }
      return isPast(dateStart, eventEndTime);
    }
  }
  console.log('isEventPast: no event provided!');
  return false;
};

/**
 * filters given events list on provided event filter
 * filtered list is returned sorted by desc event date (most recent first)
 * @param {Object[]} events - list of events to filter
 * @param {string} eventFilter - event filter value - possible values given by EVENT_FILTER object properties
 * @returns {Object[]} filtered and ordered events list
 */
export const filterEvents = (events, eventFilter) => {
  const filtered = events.filter((event) => {
    if (eventFilter === EVENT_FILTER.all) {
      return true;
    } else if (eventFilter === EVENT_FILTER.past) {
      return event.status !== EVENT_STATUS.canceled && isEventPast(event);
    } else if (eventFilter === EVENT_FILTER.canceled) {
      return event.status === EVENT_STATUS.canceled;
    } else {
      return event.status === eventFilter && !isEventPast(event);
    }
  });
  return orderEventsByDate(filtered, 'desc');
};

/**
 * returns event status label localization id
 * @param {Object} event - event to process
 * @returns {string} event status label localization id
 */
export const getEventStatusLabelId = (event) => {
  const { status } = event ?? {};
  switch (status) {
    case EVENT_STATUS.pending:
      return 'events.pending.label';
    case EVENT_STATUS.registered:
      return 'common.events.state.scheduled';
    case EVENT_STATUS.reassign:
      return 'events.state.reassign';
    case EVENT_STATUS.canceled:
    default:
      return 'common.events.state.canceled';
  }
};
