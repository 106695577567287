import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Text } from 'grommet';
import i18n from 'i18next';
import { Spinner, VariableVList } from 'src/components';
import formatDistanceToNow from 'src/utility/formatDistanceToNow';

export let commentSelectOptions;

i18n.on('initialized', function () {
  commentSelectOptions = [
    { value: '7d', label: i18n.t('common.last.days', { amount: 7 }) },
    { value: '1m', label: i18n.t('common.last.days', { amount: 30 }) },
    { value: '6m', label: i18n.t('common.last.months', { amount: 6 }) },
    { value: '9m', label: i18n.t('common.last.months', { amount: 9 }) },
    { value: '12m', label: i18n.t('common.last.months', { amount: 12 }) },
    { value: '24m', label: i18n.t('common.last.months', { amount: 24 }) },
    { value: undefined, label: i18n.t('common.all.commentaries') }
  ];
});

/**
 * Renders a list of user comments
 * based on variable height items list as each comment size is unknown
 * @prop {Object} props - component properties
 * @prop {Object[]} props.data - Array of user comments
 * @prop {boolean} props.showCompany - if true displays company name in comment header
 * @prop  {boolean} props.isLoading - data loading state
 * @prop {boolean} props.showBeneficiaryData - if true displays beneficiary data
 * @returns {React.Component} component rendering list of user comments
 */
const CommentsList = ({ data, showCompany = false, isLoading, showBeneficiaryData = false }) => {
  const { t } = useTranslation();
  const listRef = useRef(null);

  // We scroll into view when clicking on filters for smoother user experience
  useEffect(() => {
    const { current } = listRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [data]);

  const ListComponent = ({ item }) => {
    const { t } = useTranslation();
    const gmailLink = useMemo(() => {
      if (item?.beneficiaryEmail) {
        const subject = encodeURIComponent(t('page.admin.beneficiary.feedback.subject.reply'));
        const body = encodeURIComponent(t('page.admin.beneficiary.feedback.body.reply', { feedback: item.response }));
        const mailto = `mailto:${item.beneficiaryEmail}?subject=${subject}&body=${body}`;
        const gmailLink = `https://mail.google.com/mail/?extsrc=mailto&url=${encodeURIComponent(mailto)}`;
        return gmailLink;
      }
    }, [item.beneficiaryEmail, item.response, t]);

    return (
      <Box pad={'small'} gap="xsmall">
        <Box direction="row" align="center" gap="none">
          {showCompany && (
            <>
              <Text size="small" color={'text'} weight={700}>
                {item.companyName}
              </Text>
              <Text size="small">&nbsp;-&nbsp;</Text>
            </>
          )}
          <Text size="xsmall">{t('common.ago', { date: formatDistanceToNow(new Date(item.submittedAt)) })}</Text>
          {showBeneficiaryData && gmailLink && (
            <>
              <Text size="small">&nbsp;-&nbsp;</Text>
              <Anchor
                size="small"
                label={t('page.admin.feedback.beneficiary.reply')}
                href={gmailLink}
                target="_blank"
              />
            </>
          )}
        </Box>
        <Text>{item.response}</Text>
      </Box>
    );
  };

  if (isLoading) return <Spinner />;

  if (!data || data.length === 0) {
    return (
      <Text weight="bold" color="status-critical" textAlign="center">
        {t('no.feedback.comment.available')}
      </Text>
    );
  }

  return (
    <Box ref={listRef}>
      <Box pad="small">
        <Text color="brand" weight={700}>
          {t('page.admin.feedback.beneficiary.comments.count', { count: data.length })}
        </Text>
      </Box>
      <VariableVList listData={data} ListComponent={ListComponent} height={600} />
    </Box>
  );
};

export default CommentsList;
