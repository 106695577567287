import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleUser, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';
import { getEventCardBackground, isEventWithNoTokenUsed } from 'src/utility/eventUtils';
import { getPractitionerTitleLabel, getSpecialtyLabel } from 'src/utility/practitionerUtils';
import styled from 'styled-components';
import EventDetailsIcons from '../../features/events/EventDetailsIcons';
import { CardButton } from '../index';
import {
  CARD_ACTION_BOX_MARGIN,
  CARD_ACTION_BOX_MARGIN_X_2,
  CARD_ACTION_TEXT_SIZE,
  CARD_PENDING_PRO_TEXT_SIZE,
  EventLocation,
  toDate
} from './cardData';
import RoomRequirements from './RoomRequirements';

const ShadowBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(210, 210, 210, 0.08);
  border-radius: 8px;
`;

const WaitingEventCard = ({ onClickDeleteEvent, onClickUpdateEvent, event, ...rest }) => {
  const { t } = useTranslation();
  const handleClickUpdateEvent = useCallback(() => {
    onClickUpdateEvent(event);
  }, [event, onClickUpdateEvent]);
  const handleClickDeleteEvent = useCallback(() => {
    onClickDeleteEvent(event._id);
  }, [event, onClickDeleteEvent]);
  const { company, groupSessionEvent, specialty, dateStart, timeStart, timeEnd } = event ?? {};
  const isDelegateEvent = specialty?.delegate;

  const noTokenEvent = isEventWithNoTokenUsed(event, company);

  const waitingLabel = useMemo(() => {
    if (isDelegateEvent) {
      return `${t('event.practitioner.pending.label')} (${getSpecialtyLabel(specialty)})`;
    }
    if (groupSessionEvent) {
      return t('events.speaker.pending.label');
    }
    return t('common.events.waiting.for', {
      position: getPractitionerTitleLabel(specialty).toLowerCase()
    });
  }, [groupSessionEvent, specialty, isDelegateEvent, t]);

  const backgroundColor = getEventCardBackground(event);

  return (
    <Box pad="small" basis="1/3" style={{ minWidth: '350px' }}>
      <ShadowBox round="8px" overflow="hidden" align="center" background="brand" {...rest}>
        <Box
          fill="horizontal"
          background={backgroundColor}
          direction="row"
          justify="between"
          pad="xsmall"
          align="center"
        >
          <Text weight={700} color="white">
            {toDate(dateStart)}
            {' - '}
            {timeStart}
            {t('common.events.time.range.to')}
            {timeEnd}
          </Text>
          <EventDetailsIcons event={event} />
        </Box>
        <Box fill="horizontal" background={backgroundColor} direction="row" pad="xsmall">
          <Text color="white">{event.specialty.label}</Text>
        </Box>
        <Box fill="horizontal" background={backgroundColor} direction="row" justify="between" pad="xsmall">
          <EventLocation event={event} />
        </Box>
        <Box fill background="white" justify="center">
          <Box
            height="40px"
            margin={{ vertical: 'small', horizontal: 'small' }}
            align="center"
            direction="row"
            gap="small"
          >
            {noTokenEvent ? (
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                color={THEME_COLORS.danger}
                style={{ width: '45px', height: '45px' }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleUser}
                color={THEME_COLORS.brand}
                style={{ width: '45px', height: '45px' }}
              />
            )}
            <Box>
              <Text
                color={noTokenEvent ? THEME_COLORS.danger : THEME_COLORS.brand}
                weight={700}
                size={CARD_PENDING_PRO_TEXT_SIZE}
              >
                {noTokenEvent
                  ? t('admin.company.no.token.used.waiting.event.warning', {
                      specialtyLabel: getSpecialtyLabel(specialty)
                    })
                  : waitingLabel}
                {groupSessionEvent && !noTokenEvent && (
                  <>
                    <br />
                    <Text size="small" color="title">
                      {getSpecialtyLabel(specialty)}
                    </Text>
                  </>
                )}
              </Text>
              <RoomRequirements event={event} />
            </Box>
          </Box>
          {!noTokenEvent && (
            <CardButton onClick={handleClickUpdateEvent} margin={CARD_ACTION_BOX_MARGIN}>
              <Text color="brand" size={CARD_ACTION_TEXT_SIZE}>
                {t('common.events.action.modify')}
              </Text>
            </CardButton>
          )}
          <CardButton
            onClick={handleClickDeleteEvent}
            margin={noTokenEvent ? CARD_ACTION_BOX_MARGIN_X_2 : CARD_ACTION_BOX_MARGIN}
          >
            <Text color="danger" size={CARD_ACTION_TEXT_SIZE}>
              {t('common.events.action.cancel')}
            </Text>
          </CardButton>
        </Box>
      </ShadowBox>
    </Box>
  );
};

export default WaitingEventCard;
