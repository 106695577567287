import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleCheck, faCircleDashed, faCircleExclamation, faHorizontalRule } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { Box, Stack, Text } from 'grommet';
import { orderBy } from 'lodash';
import { Separator } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';
import { EVENT_STATUS } from 'src/constants';
import { isGroupSessionEventSpecialty } from 'src/features/specialties/specialtiesUtils';
import { getAppointmentsAndAvailableSlots, isEventFull, isEventTodayAndEnded } from 'src/utility/eventUtils';
import format from 'src/utility/format';

const EventMilestone = {
  creation: 'creation',
  update: 'update',
  register: 'register',
  unregister: 'unregister',
  start: 'start',
  cancel: 'cancel'
};

/**
 * @component
 * renders a single event milestone
 * icon depends on fact that it is an expected or unexpected milestone in event workflow
 * or milestone in the future
 * unexpected = event cancelled or practitioner unregister
 * displays icon, date and milestone label
 * @param {object}  props - component properties
 * @param {object}  props.milestone - milestone to process
 * @returns {React.FC} a single event milestone component
 */
const Milestone = ({ milestone }) => {
  const { date, expected, label, isFuture } = milestone;
  let iconColor = expected ? THEME_COLORS['status-ok'] : THEME_COLORS.warning;
  let icon;
  if (isFuture) {
    icon = faCircleDashed;
    iconColor = THEME_COLORS.grey;
  } else {
    icon = expected ? faCircleCheck : faCircleExclamation;
  }
  return (
    <Box align="center" style={{ minWidth: '85px' }}>
      <FontAwesomeIcon icon={icon} size="lg" color={iconColor} />
      <Text textAlign="center" size="xsmall" weight={700} color="brand">
        {format(date, 'P')}
      </Text>
      <Text textAlign="center" size="xsmall" weight={isFuture ? 'normal' : 700}>
        {label}
      </Text>
    </Box>
  );
};

/**
 * @component
 * renders an event milestone separator (icon)
 * icon color depends on fact that next milestone is in the future or not
 * @param {object}  props - component properties
 * @param {boolean}  props.linkToFutureMilestone - true if separator added to link to a next milestone which did not occur yet (in the future)
 * @returns {React.FC} event milestone separator component
 */
const MilestoneSeparator = ({ linkToFutureMilestone }) => {
  return (
    <Box align="center" width="25px" margin="small">
      <FontAwesomeIcon
        icon={faHorizontalRule}
        size="lg"
        color={linkToFutureMilestone ? THEME_COLORS.greyBackground : THEME_COLORS.text}
      />
    </Box>
  );
};

/**
 * checks if event date is past
 * includes test if event occurs today in which case it is considered
 * as past only when last event slot is past
 * @function
 * @param {Object} event - event to check
 * @returns {boolean} true if event is past now
 */
const isEventPastNow = (event) => {
  const { dateStart } = event;
  const now = new Date();
  let isPast;
  if (isSameDay(now, dateStart)) {
    isPast = isEventTodayAndEnded(event);
  } else {
    isPast = isAfter(now, dateStart);
  }
  return isPast;
};

/**
 * renders event status summary based on event date and status
 * @component
 * @param {Object} props - component properties
 * @param {Object} event - event to process
 * @returns {React.FC} Event Status Summary component
 */
const EventStatusSummary = ({ event }) => {
  const { t } = useTranslation();
  const { status } = event ?? {};
  const isPast = isEventPastNow(event);
  const { specialty, attendees } = event;
  const groupSessionEvent = isGroupSessionEventSpecialty(specialty);
  let statusText;
  let textColor = 'title';
  switch (status) {
    case 'pending':
      if (isPast) {
        statusText = groupSessionEvent ? t('event.pending.state.past.speaker') : t('event.pending.state.past');
      } else {
        statusText = groupSessionEvent ? t('event.pending.state.speaker') : t('event.pending.state');
      }
      break;
    case 'canceled':
      statusText = t('event.canceled.state');
      textColor = THEME_COLORS.danger;
      break;
    case 'reassign':
      if (isPast) {
        statusText = groupSessionEvent ? t('event.reassign.state.past.speaker') : t('event.reassign.state.past');
      } else {
        statusText = groupSessionEvent ? t('event.reassign.state.speaker') : t('event.reassign.state');
      }
      textColor = THEME_COLORS.danger;
      break;
    case 'registered':
    default:
      let count;
      let details;
      if (groupSessionEvent) {
        count = attendees.length;
      } else {
        const { nbAppointments } = getAppointmentsAndAvailableSlots(event);
        count = nbAppointments;
      }
      if (count === 0) {
        details = groupSessionEvent ? t('event.state.no.registration') : t('event.state.no.appointment');
      } else {
        if (isEventFull(event)) {
          details = t('event.state.full');
        } else {
          details = groupSessionEvent
            ? t('event.state.registration.count', { count })
            : t('event.state.appointment.count', { count });
        }
      }
      if (isPast) {
        if (count === 0) {
          statusText = groupSessionEvent
            ? t('event.registered.state.past.no.registration')
            : t('event.registered.state.past.no.appointment');
        } else {
          statusText = t('event.registered.state.past', { details });
        }
      } else {
        statusText = groupSessionEvent
          ? t('event.registered.state.speaker', { details })
          : t('event.registered.state', { details });
      }
      break;
  }
  return (
    <>
      <Box margin="small" align="center">
        {groupSessionEvent && (
          <Text textAlign="center" size="small" color="title">
            {t('event.group.session.tip.label')}
          </Text>
        )}
        <Text textAlign="center" size="small" color={textColor} weight={750}>
          {`${t('event.status.line', { past: isPast ? t('event.past.date') : '', status: statusText })}`}
        </Text>
      </Box>
      <Box width="98%" margin={{ bottom: 'small' }}>
        <Separator />
      </Box>
    </>
  );
};

/**
 * Renders a timeline chart for event that shows event milestones
 * like creation, cancellation, practitioner register/unregister or the planned event date
 * @component
 * @param {object}  props - component properties
 * @param {object}  props.event - event to process
 * @returns {React.FC} The event milestones chart component
 */
const EventMilestonesChart = ({ event }) => {
  const { t } = useTranslation();

  const eventMilestones = useMemo(() => {
    const { status, createdAt, updatedAt, dateStart, registerDate, groupSessionEvent, unregisterHistory = [] } = event;
    const dates = [createdAt, dateStart];
    const now = new Date();
    const milestones = [];
    let date = new Date(createdAt);
    milestones.push({
      date,
      expected: true,
      isFuture: isBefore(now, date),
      event: EventMilestone.creation,
      label: t('event.milestone.create')
    });
    date = new Date(dateStart);
    milestones.push({
      date,
      expected: true,
      isFuture: !isEventPastNow(event),
      event: EventMilestone.start,
      label: t('event.milestone.start')
    });
    if (registerDate) {
      dates.push(registerDate);
      const date = new Date(registerDate);
      milestones.push({
        date,
        isFuture: isBefore(now, date),
        expected: true,
        event: EventMilestone.register,
        label: groupSessionEvent ? t('event.milestone.register.speaker') : t('event.milestone.register')
      });
    }

    if (unregisterHistory.length) {
      for (const unregister of unregisterHistory) {
        dates.push(unregister.date);
        const date = new Date(unregister.date);
        milestones.push({
          date,
          expected: false,
          isFuture: isBefore(now, date),
          event: EventMilestone.unregister,
          label: groupSessionEvent ? t('event.milestone.unregister.speaker') : t('event.milestone.unregister')
        });
      }
    }

    const formatDates = [];
    for (const date of dates) {
      const d = new Date(date);
      formatDates.push(format(d, 'P'));
    }

    if (updatedAt) {
      const date = new Date(updatedAt);
      if (status === EVENT_STATUS.canceled) {
        milestones.push({
          date,
          expected: false,
          isFuture: isBefore(now, date),
          event: EventMilestone.cancel,
          label: t('event.milestone.cancel')
        });
      } else if (updatedAt !== createdAt) {
        const d = new Date(updatedAt);
        const formatUpdateDate = format(d, 'P');
        // only add update milestone if date doesn't match any of other milestones created
        if (!formatDates.includes(formatUpdateDate)) {
          milestones.push({
            date,
            expected: true,
            isFuture: isBefore(now, date),
            event: EventMilestone.update,
            label: t('event.milestone.update')
          });
        }
      }
    }

    return orderBy(milestones, [(milestone) => milestone.date], ['asc']);
  }, [event, t]);

  return (
    <Box align="center" fill>
      <EventStatusSummary event={event} />
      <Stack fill>
        <Box direction="row" align="center">
          {eventMilestones.map((milestone, index, array) => (
            <>
              <Milestone key={`milestone-${index}`} milestone={milestone} />
              {index < array.length - 1 && (
                <MilestoneSeparator key={`separator-${index}`} linkToFutureMilestone={array[index + 1].isFuture} />
              )}
            </>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default EventMilestonesChart;
