import { createSlice } from '@reduxjs/toolkit';
import {
  createEvent,
  deleteEvent,
  getCompanyEvents,
  getPartnerPastEvents,
  getPartnerScheduledEvents,
  getPartnerWaitingEvents,
  replaceEventPartner,
  requestAttendanceStatusBulkUpdate,
  requestUpdateBooking,
  requestUpdateEvent,
  requestUpdateReservation
} from 'src/utility/api';
import { isEventPast, isPast } from 'src/utility/eventUtils';
import { canManageEvent } from 'src/utility/permissions';
import { orderEventsByDate } from 'src/utility/sort';

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    waitingEvents: [],
    scheduledEvents: [],
    pastEvents: [],
    reassignEvents: [],
    currentEvent: {}
  },
  reducers: {
    updateCurrentEvent: (state, action) => {
      state.currentEvent = action.payload;
    },
    updateWaitingEvents: (state, action) => {
      state.waitingEvents = action.payload;
    },
    updateScheduledEvents: (state, action) => {
      state.scheduledEvents = action.payload;
    },
    updateReassignEvents: (state, action) => {
      state.reassignEvents = action.payload;
    },
    updatePastEvents: (state, action) => {
      state.pastEvents = action.payload;
    }
  }
});

export const {
  updateCurrentEvent,
  updateWaitingEvents,
  updateScheduledEvents,
  updatePastEvents,
  updateReassignEvents
} = eventsSlice.actions;

// Employee Events

const refreshEmployeeWaitingEvents = (events) => (dispatch) => {
  try {
    const waitingEvents = events.filter((e) => {
      return e?.practitioner && !isEventPast(e);
    });
    const orderedEvents = orderEventsByDate(waitingEvents, 'asc');
    dispatch(updateWaitingEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshEmployeeWaitingEvents | ', error);
    throw error;
  }
};

const isUserRegistered = (event, user, isPastRegistration) => {
  const { dateStart, practitioner, groupSessionEvent, attendees = [], appointments = [] } = event;
  if (groupSessionEvent) {
    return practitioner && attendees.some((attendee) => attendee?.employee === user?._id);
  }
  return (
    practitioner &&
    appointments.some((item) => {
      const isUser = item.employee === user?._id;
      return isUser && isPast(dateStart, item.end) === isPastRegistration;
    })
  );
};

const refreshEmployeeScheduledEvents = (events) => (dispatch, getState) => {
  try {
    const { auth } = getState();
    const scheduledEvents = events.filter((e) => {
      return !isEventPast(e) && isUserRegistered(e, auth?.user, false);
    });
    const orderedEvents = orderEventsByDate(scheduledEvents, 'asc');
    dispatch(updateScheduledEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshEmployeeScheduledEvents | ', error);
    throw error;
  }
};

const refreshEmployeePastEvents = (events) => (dispatch, getState) => {
  try {
    const { auth } = getState();
    const pastEvents = events.filter((e) => {
      const { groupSessionEvent } = e;
      if (groupSessionEvent) {
        return isEventPast(e) && isUserRegistered(e, auth?.user, true);
      }
      return isUserRegistered(e, auth?.user, true);
    });
    const orderedEvents = orderEventsByDate(pastEvents, 'desc');
    dispatch(updatePastEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshEmployeePastEvents | ', error);
    throw error;
  }
};

export const fetchAndRefreshEmployeeEvents = () => async (dispatch) => {
  try {
    const data = await getCompanyEvents();
    const { events } = data ?? {};
    if (events) {
      dispatch(refreshEmployeeWaitingEvents(events));
      dispatch(refreshEmployeeScheduledEvents(events));
      dispatch(refreshEmployeePastEvents(events));
    }
    return { status: 'success' };
  } catch (error) {
    console.error('eventsSlice.js/fetchAndRefreshEmployeeEvents ', error);
    throw error;
  }
};

// Get practitioner/speaker Events
// radius property only valid for practitioners

export const fetchPartnerWaitingEvents = (radius) => async (dispatch) => {
  try {
    const data = await getPartnerWaitingEvents(radius);
    const { events } = data ?? {};
    if (events) {
      dispatch(updateWaitingEvents(events));
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchPartnerWaitingEvents | ', error);
    throw error;
  }
};

export const fetchPartnerScheduledEvents = () => async (dispatch) => {
  try {
    const data = await getPartnerScheduledEvents();
    const { events } = data ?? {};
    if (events) {
      const scheduledEvents = events.filter((e) => e.practitioner && !isEventPast(e));
      const orderedEvents = orderEventsByDate(scheduledEvents, 'asc');
      dispatch(updateScheduledEvents(orderedEvents));
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchPartnerScheduledEvents | ', error);
    throw error;
  }
};

export const fetchPartnerPastEvents = () => async (dispatch) => {
  try {
    const data = await getPartnerPastEvents();
    const { events } = data ?? {};
    if (events) {
      const scheduledEvents = events.filter((e) => e.practitioner && isEventPast(e));
      const orderedEvents = orderEventsByDate(scheduledEvents, 'asc');
      dispatch(updatePastEvents(orderedEvents));
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchPartnerPastEvents | ', error);
    throw error;
  }
};

export const fetchAndRefreshPartnerEvents = () => async (dispatch) => {
  try {
    await dispatch(fetchPartnerWaitingEvents());
    await dispatch(fetchPartnerScheduledEvents());
    await dispatch(fetchPartnerPastEvents());
    return { status: 'success' };
  } catch (error) {
    console.error('eventsSlice.js/fetchAndRefreshPartnerEvents | ', error);
    throw error;
  }
};

// Company Events

const refreshCompanyWaitingEvents = (events) => (dispatch) => {
  try {
    const waitingEvents = events.filter((e) => !e.practitioner && !isEventPast(e));
    const orderedEvents = orderEventsByDate(waitingEvents, 'asc');
    dispatch(updateWaitingEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshCompanyWaitingEvents | ', error);
    throw error;
  }
};

export const fetchCompanyWaitingEvents = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    if (auth?.user && canManageEvent(auth.user)) {
      // only events in pending state
      const data = await getCompanyEvents('pending');
      const { events } = data ?? {};
      if (events) {
        dispatch(refreshCompanyWaitingEvents(events));
      }
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchCompanyWaitingEvents | ', error);
    throw error;
  }
};

const refreshCompanyScheduledEvents = (events) => (dispatch) => {
  try {
    const scheduledEvents = events.filter((e) => e.practitioner && !isEventPast(e));
    const orderedEvents = orderEventsByDate(scheduledEvents, 'asc');
    dispatch(updateScheduledEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshCompanyScheduledEvents | ', error);
    throw error;
  }
};

export const fetchCompanyScheduledEvents = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    if (auth?.user && canManageEvent(auth.user)) {
      // only events in registered state
      const data = await getCompanyEvents('registered');
      const { events } = data ?? {};
      if (events) {
        dispatch(refreshCompanyScheduledEvents(events));
      }
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchCompanyScheduledEvents | ', error);
    throw error;
  }
};

const refreshCompanyPastEvents = (events) => (dispatch) => {
  try {
    const pastEvents = events.filter((e) => isEventPast(e));
    const orderedEvents = orderEventsByDate(pastEvents, 'desc');
    dispatch(updatePastEvents(orderedEvents));
  } catch (error) {
    console.error('eventsSlice.js/refreshCompanyPastEvents | ', error);
    throw error;
  }
};

export const fetchCompanyPastEvents = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    if (auth?.user && canManageEvent(auth.user)) {
      // fetch all events as Past is not a state but depends on event date
      // and filtering will be done in refreshCompanyPastEvents()
      const data = await getCompanyEvents();
      const { events } = data ?? {};
      if (events) {
        dispatch(refreshCompanyPastEvents(events));
      }
    }
  } catch (error) {
    console.error('eventsSlice.js/fetchCompanyPastEvents | ', error);
    throw error;
  }
};

export const fetchAndRefreshCompanyEvents = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    if (auth?.user && canManageEvent(auth.user)) {
      const data = await getCompanyEvents();
      const { events } = data ?? {};
      if (events) {
        await dispatch(refreshCompanyWaitingEvents(events));
        await dispatch(refreshCompanyScheduledEvents(events));
        await dispatch(refreshCompanyPastEvents(events));
      }
    }
    return { status: 'success' };
  } catch (error) {
    console.error('eventsSlice.js/fetchAndRefreshCompanyEvents | ', error);
    throw error;
  }
};

// Events management

export const submitNewEvent = (eventData, refreshEvents) => async (dispatch) => {
  try {
    const data = await createEvent(eventData);
    const { event } = data ?? {};
    return { status: 'success', data: { event } };
  } catch (error) {
    console.error('eventsSlice.js/submitNewEvent | ', error);
    return { status: 'error', data: error.message };
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

export const updateEvent = (id, data, refreshEvents) => async (dispatch) => {
  try {
    const ret = await requestUpdateEvent(id, data);
    const { event } = ret ?? {};
    return { status: 'success', data: { event } };
  } catch (error) {
    console.error('eventsSlice.js/updateEvent | ', error);
    return { status: 'error', data: error.message };
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

export const attendanceStatusBulkUpdate = (data, refreshEvents) => async (dispatch) => {
  try {
    const ret = await requestAttendanceStatusBulkUpdate(data);
    const { status } = ret ?? {};
    console.log('attendanceStatusBulkUpdate: status=', status);
    return { status };
  } catch (error) {
    console.error('eventsSlice.js/updateEvent | ', error);
    throw error;
  } finally {
    if (refreshEvents) {
      console.log('attendanceStatusBulkUpdate: refreshing events...');
      dispatch(refreshEvents());
    }
  }
};

export const updateBooking = (id, data, refreshEvents) => async (dispatch) => {
  const { slot, action } = data;
  try {
    // data must contain groupSessionEvent property for group session event to skip slot presence check
    // reason only valid for unbook action case
    // reason = 0 => unbook done by practitioner
    // otherwise code of unbook reason as provided by beneficiary
    const ret = await requestUpdateBooking(id, action, slot, data);
    const { event } = ret ?? {};
    return { status: 'success', data: { event } };
  } catch (error) {
    console.error('eventsSlice.js/updateBooking | ', error);
    return { status: 'error', data: error.message };
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

export const updateReservation =
  (id, { slots }, refreshEvents) =>
  async (dispatch) => {
    try {
      const ret = await requestUpdateReservation(id, slots);
      const { event } = ret ?? {};
      return { status: 'success', data: { event } };
    } catch (error) {
      console.error('eventsSlice.js/updateReservation | ', error);
      return { status: 'error', data: error.message };
    } finally {
      if (refreshEvents) {
        dispatch(refreshEvents());
      }
    }
  };

export const removeEvent = (eventId, refreshEvents) => async (dispatch) => {
  try {
    await deleteEvent(eventId);
  } catch (error) {
    throw error;
  } finally {
    if (refreshEvents) {
      dispatch(refreshEvents());
    }
  }
};

/**
 * triggers action to replace partner on given event
 * ie performs API request
 * @param {string} eventId - event id
 * @param {string} practitionerId - id of new practitioner
 * @param {string} [providerId] - Id of new provider if practitioner is of referent type
 */
export const replaceEvent = (eventId, practitionerId, providerId) => async (dispatch) => {
  try {
    await replaceEventPartner(eventId, practitionerId, providerId);
  } catch (error) {
    throw error;
  }
};

export default eventsSlice.reducer;
