import axios from 'axios';
import { isArray, isObject } from 'lodash';
import {
  AppointmentStatus,
  AttendeeStatus,
  BASIC_IMPORT_MODE,
  COMPANY_TOKEN_SEARCH_MODES,
  EVENT_STATUS
} from 'src/constants';
import { getAuthUser, getAuthUserSite } from '../app/store';
import { FileUploadKind, UserRole } from '../config';
import { getAppConfig, getReactProcessVar } from './appConfig';

const EXPIRED_TOKEN_STATUS_CODE = 498;

// max value the unbook reason code can hold
const MAX_REASON_VALUE = 7;

const sessionTokens = {
  auth: null,
  refresh: null
};

const SIGN_IN_URL = '/signin';

const AUTH_REFRESH_API = '/auth/refresh';
const SIGN_IN_API = '/auth/signin';
const SIGN_OUT_API = '/auth/signout';
const CONFIG_API = '/config/app-info';

// Urls that don't require token
const NO_TOKEN_URLS = [
  SIGN_IN_API,
  CONFIG_API,
  '/specialties/options',
  '/auth/signup',
  '/auth/validate-email',
  '/auth/send-reset-email',
  '/auth/reset-password',
  '/auth/complete-practitioner-signup',
  '/auth/company-events',
  '/companies/code',
  '/users/code-member',
  '/users/user-token',
  '/users/update-email',
  '/users/check-registration'
];

const DEFAULT_REQUEST_TIMEOUT = 30000;
const LONG_REQUEST_TIMEOUT = 240000;
const BACKEND_API_URL = `${getAppConfig().backendUrl}/api`;
const { requestTimeout } = getAppConfig();
const JSON_PAYLOAD_LIMIT = Number(getReactProcessVar('REACT_APP_JSON_PAYLOAD_SIZE_LIMIT', true));

let isRefreshingToken = false; // true when auth token refresh in progress
let isSignOutInProgress = false; // true when sign out API call refresh in progress
let refreshSubscribers = [];

const payloadTooLarge = (payloadObject, caller) => {
  // Shield - bypass test with error when limit invalid
  if (JSON_PAYLOAD_LIMIT === 0 || isNaN(JSON_PAYLOAD_LIMIT)) {
    console.error(`Bypassing payload size check as limit is not valid ${JSON_PAYLOAD_LIMIT}`);
    return true;
  }
  console.log(`Checking JSON payload size against ${JSON_PAYLOAD_LIMIT} bytes limit...`);
  const dataString = JSON.stringify(payloadObject);
  const payloadSize = new Blob([dataString]).size;
  if (payloadSize > JSON_PAYLOAD_LIMIT) {
    console.warn(`Request payload too large (${payloadSize} bytes) for ${caller} API!`);
    return true;
  }
  return false;
};

export const getAuthToken = () => sessionTokens.auth;

export const getRefreshToken = () => sessionTokens.refresh;

const deleteAuthToken = () => {
  sessionTokens.auth = null;
};
const deleteRefreshToken = () => {
  sessionTokens.refresh = null;
};

export const saveTokens = (token, refreshToken = null) => {
  if (token) {
    sessionTokens.auth = token;
  }
  if (refreshToken) {
    sessionTokens.refresh = refreshToken;
  }
};

/**
 * Sets authorization header with JWT auth token
 * if config provided sets axios default config header
 * if originalRequest provided sets header in originalRequest object
 * @param {Object} config - axios request config object
 * @param {Object} [originalRequest=null] - originalRequest object to configure header for
 * @returns {boolean} true if token found and set, false otherwise
 */
const generateAuthHeader = (config, originalRequest = null) => {
  const isRefresh = config && config.url === AUTH_REFRESH_API;
  const token = isRefresh ? getRefreshToken() : getAuthToken();
  if (token) {
    const authHeader = `Bearer ${token}`;
    if (config) {
      config.headers['Authorization'] = authHeader;
    }
    if (originalRequest) {
      originalRequest.headers['Authorization'] = authHeader;
    }
    return true;
  }
  console.warn(`No token defined for url: ${config ? config.url : originalRequest ? originalRequest.url : '?'}`);
  return false;
};

/**
 * adds a promise to perform request after token refresh is completed
 * @param {Object} req - request to add to subscribers for processing after refresh
 * @returns {Promise}
 */
const addPendingRequest = (req) => {
  console.log(`Token refresh in progress, adding ${req.url} to subscribers...`);
  return new Promise((resolve) => {
    refreshSubscribers.push(() => {
      console.debug(`Processing pending req for url: ${req.url} ...`);
      generateAuthHeader(null, req);
      resolve(axios(req));
    });
  });
};

const isTokenRequired = (url) => !NO_TOKEN_URLS.some((path) => url.startsWith(path));

/**
 * Create an axios instance with bearer token and base url set up
 */
const api = axios.create({
  baseURL: BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: requestTimeout ?? DEFAULT_REQUEST_TIMEOUT
});

api.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    console.log(`Requesting url: ${config.method} ${config.url}...`);
    if (isTokenRequired(config?.url)) {
      // a token refresh is already in progress don't even try to make request => Abort
      if (isRefreshingToken && config?.url !== AUTH_REFRESH_API) {
        controller.abort();
        addPendingRequest(config);
        return {
          ...config,
          signal: controller.signal
        };
      }
      if (generateAuthHeader(config)) {
        if (config?.url === SIGN_OUT_API) {
          // logout action - delete token first to avoid other reqs while completing signout
          deleteAuthToken();
        }
        return config;
      }
      if (config?.url !== SIGN_OUT_API) {
        // if token missing for sign out, we don't care as disconnecting anyway...
        console.log('No token, redirecting to sign in');
        window.location.href = SIGN_IN_URL;
      }
      return Promise.reject('No token');
    }
    return config;
  },
  (error) => {
    console.error('Axios request error: ', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // status code within 2xx range - do something with response data
    return response;
  },
  (error) => {
    console.error('Axios response error: ', error);
    const originalRequest = error.config;
    const respStatus = error?.response?.status;
    switch (respStatus) {
      case EXPIRED_TOKEN_STATUS_CODE: // auth token expiry
        // this code can only be sent by Auth server middleware when token expired or invalidated
        // try to refresh Auth token except if error on sign out request
        console.debug(`Got ${EXPIRED_TOKEN_STATUS_CODE} expired token error for url: ${originalRequest.url}`);
        if (originalRequest.url !== SIGN_OUT_API && originalRequest.url !== AUTH_REFRESH_API) {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            deleteAuthToken();
            const authUser = getAuthUser();
            const refreshToken = getRefreshToken();
            if (authUser && refreshToken) {
              const data = {
                email: authUser?.email,
                id: authUser?._id
              };
              const userSite = getAuthUserSite();
              if (userSite) {
                data.site = userSite;
              }
              console.log('Requesting token refresh...');
              return api
                .post(AUTH_REFRESH_API, data)
                .then((res) => {
                  if (res.status === 201) {
                    const newToken = res.data.token;
                    saveTokens(newToken);
                    console.debug(`Requesting ${originalRequest.url} again with new token...`);
                    generateAuthHeader(null, originalRequest);
                    isRefreshingToken = false;
                    const response = axios(originalRequest);
                    if (refreshSubscribers.length) {
                      console.debug(`${refreshSubscribers.length} pending requests to process...`);
                      refreshSubscribers.forEach((subscriber) => subscriber());
                      refreshSubscribers = [];
                    }
                    return response;
                  }
                })
                .catch((err) => {
                  console.error(`Refresh token API error: ${err}`);
                  // failed to refresh auth token, we need to redirect to sign in page as expired session
                  window.location.href = `${SIGN_IN_URL}?exp=1`;
                  return Promise.reject(error);
                });
            }
          }
          // token refresh already in progress, add request to refresh subscribers list
          return addPendingRequest(originalRequest);
        }
        console.warn('token expiry error! redirecting to sign in...');
        window.location.href = SIGN_IN_URL;
        return Promise.reject(error);
      case 401:
        deleteAuthToken();
        if (originalRequest.url === AUTH_REFRESH_API) {
          // auth token refresh failed, redirect to sign in
          deleteRefreshToken();
          console.warn('Auth token refresh denied! redirect user to sign in...');
          window.location.href = `${SIGN_IN_URL}?exp=1`;
        }
        // Unauthorized - multiple error causes:  invalid credentials, email not validated ...
        console.warn(`401 Response error message: ${error?.response?.data?.message}`);
        return Promise.reject(error);
      case 403:
        // Forbidden - mainly when user doesn't have rights or permission (as admin)
        console.warn(`403 Response error message: ${error?.response?.data?.message}`);
        return Promise.reject(error);
      default:
        console.error(`${respStatus} Response error message: ${error?.response?.data?.message}`);
        return Promise.reject(error);
    }
  }
);

export default api;

const logAndThrowAxiosError = (src, err) => {
  // ignore canceled requests generated by req interceptor when refresh in progress
  // also ignore errors with EXPIRED_TOKEN_STATUS_CODE code as managed by token refresh process
  const message = err.message || '';
  const details = err?.response?.data?.message ? ` | ${err.response.data.message}` : '';
  const method = err?.config?.method || '';
  const url = (err?.config?.baseURL || '') + (err?.config?.url || '');
  const code = err?.response?.status || '';
  console.error(`${src} error: ${method.toUpperCase()} ${url} - status: ${code} - message: ${message}${details}`);
  if (
    err.code !== 'ERR_CANCELED' &&
    err.code !== 'ERR_NETWORK' &&
    err?.response?.status !== EXPIRED_TOKEN_STATUS_CODE
  ) {
    if (err.code === 'ECONNABORTED') {
      console.log('timeout! redirecting to error page...');
      window.location.href = '/error';
    }
    throw formatAxiosError(err);
  }
};

/**
 * Format response provided by axios to only have the necessary data payload
 * @param {Object} axiosResponse
 * @returns {Object} An object with the response data and the http code:
 * {
 *   status : 'success'
 *   data: response data
 *   httpStatus: number
 * }
 */
export const formatAxiosResponse = (axiosResponse) => {
  const formattedResponse = {
    ...(axiosResponse?.data || {}),
    httpStatus: axiosResponse?.status || 500
  };
  return formattedResponse;
};

/**
 * Format error response provided by axios to only have the necessary data payload
 * @param {Object} axiosError
 * @returns {Object} An object with the response data and the http code:
 * {
 *   status :  'error'
 *   data: object => status & message properties from axios response data object
 *   httpStatus: number
 * }
 */
export const formatAxiosError = (axiosError) => {
  const { response = {} } = axiosError;
  const { data = {} } = response;
  const formattedError = new Error(data?.message || axiosError.code || axiosError?.message || 'unknown_error');
  formattedError.status = data?.status || 'error';
  formattedError.httpStatus = response?.status || 500;
  formattedError.data = {
    message: data?.message || data?.errors?.message || '',
    status: data?.status || data?.errors?.status || 'error'
  };
  return formattedError;
};

/**
 * Add or upload a file on the server
 * @param {File} fileToUpload The file to upload
 * @param {string} originalName The original name of the file
 * @param {string} targetId The targeted user or company Id
 * @param {string} targetType The target type can be user or company for instance
 * @param {FileUploadKind} [fileKind] The file kind
 * @param {string} [fileIdToUpdate] The previous file to update by
 * @returns {Promise<Object>}
 */
export const addOrUpdateFileUpload = async (
  fileToUpload,
  originalName,
  targetId,
  targetType,
  fileKind = FileUploadKind.other,
  fileIdToUpdate = '',
  fileKey
) => {
  if (!fileToUpload) {
    throw new Error(`api.js/addOrUpdateFileUpload - No file provided`);
  }
  const formData = new FormData();
  if (fileKey) {
    formData.append('fileKey', fileKey);
  }
  formData.append('file', fileToUpload, originalName);
  formData.append('kind', fileKind);
  formData.append('targetType', targetType);
  formData.append('update', fileIdToUpdate);

  try {
    const response = await api.post(`/files/${targetId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    });
    return response.data.file;
  } catch (axiosError) {
    logAndThrowAxiosError('addOrUpdateFileUpload', axiosError);
  }
};

/**
 * Add one user or several users to a company
 * @param {Object|Object[]} userData Data of the user(s) to add
 * @param {string} currentUserCompanyId Id of the current user
 * @param {UserRole} role role of user(s) to add (admin or employee)
 * @param {number} [importMode=BASIC_IMPORT_MODE]  only in case of multiple users import - defines import mode
 * @returns {Promise<Object>} The user(s) that has been added
 */
export const postCompanyMembers = async (userData, currentUserCompanyId, role, importMode = BASIC_IMPORT_MODE) => {
  let userDataExtended;
  let endpoint;
  if (!userData) {
    throw new Error('No user data provided');
  }

  if (role === UserRole.adminCompany) {
    if (isObject(userData)) {
      userDataExtended = {
        ...userData,
        role: UserRole.adminCompany,
        company: userData.company || currentUserCompanyId || ''
      };
      endpoint = '/users/company-admin';
      console.log('api.js/postCompanyMembers:: adding company Admin...');
    }
  } else {
    if (isArray(userData)) {
      userDataExtended = userData.map((ud) => {
        return { ...ud, role: ud.role || UserRole.employee, company: ud.company || currentUserCompanyId || '' };
      });
      endpoint = `/users/company-members?mode=${importMode}`;
      console.log(
        `api.js/postCompanyMembers:: importing ${userDataExtended.length} company beneficiaries - mode: ${importMode}...`
      );
    } else if (isObject(userData)) {
      userDataExtended = {
        ...userData,
        role: userData.role || UserRole.employee,
        company: userData.company || currentUserCompanyId || ''
      };
      endpoint = '/users/company-member';
      console.log('api.js/postCompanyMembers:: adding company beneficiary...');
    }
  }
  if (!endpoint) {
    throw new Error('Unable to parse user data');
  }
  if (payloadTooLarge(userDataExtended, 'api.js/postCompanyMembers')) {
    throw new Error('request entity too large');
  }
  try {
    return api.post(endpoint, userDataExtended, { timeout: LONG_REQUEST_TIMEOUT });
  } catch (axiosError) {
    logAndThrowAxiosError('postCompanyMembers', axiosError);
  }
};

/**
 * Delete a file from servers
 * @param {string} fileId The file kind
 * @returns {Promise<void>}
 */
export const deleteFileUpload = async (fileId) => {
  try {
    await api.delete(`/files/${fileId}`);
  } catch (axiosError) {
    logAndThrowAxiosError('deleteFileUpload', axiosError);
  }
};

/**
 * Mark the survey as completed for the current user
 * @param {string} surveyId The completed survey
 * @returns {Promise<Object>}
 */
export const completeSurvey = async (surveyId) => {
  try {
    return await api.put(`/users/complete-survey/${surveyId}`);
  } catch (axiosError) {
    logAndThrowAxiosError('completeSurvey', axiosError);
  }
};

/**
 * Get the results of a health survey for a specified company
 * @param {string} companyId The company id
 * @returns {Promise<Object>}
 */
export const getHealthSurveyResults = async (companyId) => {
  if (!companyId) {
    throw new Error('Company id not specified');
  }
  try {
    return await api.get(`/survey/health-results/${companyId}`);
  } catch (axiosError) {
    logAndThrowAxiosError('getHealthSurveyResults', axiosError);
  }
};

export const getCompanyStatistics = async (companyId) => {
  if (!companyId) {
    throw new Error('Company id not specified');
  }
  try {
    return await api.get(`/companies/${companyId}/statistics`);
  } catch (axiosError) {
    logAndThrowAxiosError('getCompanyStatistics', axiosError);
  }
};

/**
 * fetches event tokens stats for companies matching search criteria
 * @param {number} searchMode - search mode
 * search mode = COMPANY_TOKEN_SEARCH_MODES.expired => companies having expired event tokens
 * search mode = COMPANY_TOKEN_SEARCH_MODES.tokenThreshold => companies having available event tokens, less than threshold
 * @param {number} [threshold] - threshold value if search mode = tokenThreshold
 * @returns {Promise<Object>}
 */
export const getCompaniesTokenStatistics = async (searchMode, threshold) => {
  if (searchMode !== COMPANY_TOKEN_SEARCH_MODES.expired && searchMode !== COMPANY_TOKEN_SEARCH_MODES.tokenThreshold) {
    throw new Error('Missing or invalid search mode value!');
  }
  if (searchMode === COMPANY_TOKEN_SEARCH_MODES.tokenThreshold && !threshold) {
    throw new Error('No threshold value provided!');
  }

  try {
    const params = new URLSearchParams('');
    const query = '/companies/token-statistics?';
    params.set('search', searchMode);
    if (threshold) {
      params.set('threshold', threshold);
    }
    const { data = {} } = await api.get(`${query}${params.toString()}`);
    return data;
  } catch (axiosError) {
    logAndThrowAxiosError('getCompaniesTokenStatistics', axiosError);
  }
};

export const getPartnerEvents = async (partnerId) => {
  if (!partnerId) {
    throw new Error('Partner id must be specified');
  }
  try {
    return await api.get('/event/partner/' + partnerId);
  } catch (axiosError) {
    logAndThrowAxiosError('getPartnerEvents', axiosError);
  }
};

export const sendInvitationEmail = async (userIds) => {
  if (!userIds || !userIds.length) {
    console.error('sendInvitationEmail:: No user Id(s) provided!');
    throw new Error('user ids must be specified');
  }
  try {
    return await api.post(`/users/send-invitation`, { userIds: isArray(userIds) ? userIds : [userIds] });
  } catch (axiosError) {
    logAndThrowAxiosError('sendInvitationEmail', axiosError);
  }
};

export const getReviewForEvent = async (eventId) => {
  if (!eventId) {
    throw new Error('Event id must be specified');
  }
  try {
    return await api.get(`/survey/event-rating/${eventId}`);
  } catch (axiosError) {
    logAndThrowAxiosError('getReviewForEvent', axiosError);
  }
};

/**
 * Get feedback results for a specific company or for all companies (only for platformImprovement)
 * @param {string|null} companyId - Company Id we want results from if not provided "/survey/feedback-results" is being hit to retrieve all results regardless of the company
 * @param {number|null} amount - filter on unit of time amount to fetch comments from the last X unit of time amount (applied only on platformImprovement)
 * @param {string|null} unit - unit of time to fetch comments from
 * @param {string|null} only - when defined it indicates to the API that we just want the specified props over all results (platformImprovement is the only prop supported for now)
 * @returns {Promise<Object>} API response object when request completed
 */
export const getFeedbackResults = async (companyId, amount, unit, only) => {
  try {
    let url = `/survey/feedback-results${companyId ? `/${companyId}` : ''}`;

    if (amount) {
      if (!unit) throw new Error('A unit of time should be specified (month or day are supported)');
      url += `?amount=${amount}&unit=${unit}`;
    }
    if (only) {
      url += `${amount ? '&' : '?'}only=${only}`;
    }
    return await api.get(url);
  } catch (axiosError) {
    logAndThrowAxiosError('getFeedbackResults', axiosError);
  }
};

export const checkUserRegistration = async (email) => {
  if (!email) {
    throw new Error('email must be specified');
  }
  try {
    const axiosResponse = await api.put(`/users/check-registration/${email}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('checkUserRegistration', axiosError);
  }
};

/**
 * returns the list of events available for booking in company
 * user Id is provided as a shield
 * request will only return data if user is actually member of the company if user Id is provided
 *
 * each event has :
 * type: the practitioner specialty
 * dateStart: the event date in DD/MM/YYYY format
 * practitioner: the practitioner name in firstName LastName format
 * @param {string} company - company Id
 * @param {string} [userId=''] - user Id if provided or ''
 * @returns {Promise<Object>} API response object when request completed
 */
export const getCompanyAvailableEvents = async (company, userId = '') => {
  try {
    const axiosResponse = await api.post('/auth/company-events', { company, user: userId });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getCompanyAvailableEvents', axiosError);
  }
};

export const getEventsByDateRange = async (rangeStart, rangeEnd) => {
  try {
    const axiosResponse = await api.get(`/event/?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getEventsByDateRange', axiosError);
  }
};

/**
 * Fetch future events (dateStart greater or equal than today) with "pending" status
 * @returns {Promise<Object>} API response object when request completed
 */
export const getWaitingEvents = async () => {
  try {
    const axiosResponse = await api.get(`/event/waiting`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getWaitingEvents', axiosError);
  }
};

/**
 * Fetch canceled events for current month
 * @returns {Promise<Object>} API response object when request completed
 */
export const getCanceledEvents = async () => {
  try {
    const axiosResponse = await api.get(`/event/canceled`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getCanceledEvents', axiosError);
  }
};

/**
 * Fetch future pending/scheduled/reassign events of given specialty Id
 * @param {string} specialtyId - id of specialty to search pending/scheduled events for
 * @returns {Promise<Object>} API response object when request completed
 */
export const getSpecialtyEvents = async (specialtyId) => {
  try {
    const axiosResponse = await api.get(`/event/specialty/${specialtyId}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getSpecialtyEvents', axiosError);
  }
};

/**
 * Fetch all partners eligible for given event practitioner or speaker replacement
 * @param {string} eventId - id of event to search replacement partners for
 * @returns {Promise<Object>} API response object when request completed
 */
export const getReplacementPartners = async (eventId) => {
  try {
    const axiosResponse = await api.get(`/event/${eventId}/partners`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getReplacementPartners', axiosError);
  }
};

/**
 * Fetch events in 'reassign' status
 * @returns {Promise<Object>} API response object when request completed
 */
export const getReassignEvents = async () => {
  try {
    const axiosResponse = await api.get(`/event/reassign`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getReassignEvents', axiosError);
  }
};

/**
 * fetches all events for user company (employee or admin-company user role)
 * or given company Id if user is VB admin
 * optional event status can be provided to only fetch given event status
 * possible values are :
 * 'all' : all event statuses including 'canceled'
 * 'noCanceled' (default if not provided) : all event statuses but 'canceled'
 * 'canceled': only events in canceled state
 * 'registered': only events in registered state
 * 'pending': only events in pending state
 * 'reassign': only events in reassign state
 * @param {string|undefined} [eventStatus=undefined] - optional event status filter
 * @param {string|undefined} [companyId=undefined] - optional company Id
 * @returns {Promise<Object>} API response object when request completed
 */
export const getCompanyEvents = async (eventStatus = undefined, companyId) => {
  try {
    let queryStringValue = '';
    if (eventStatus) {
      if (!['all', ...Object.values(EVENT_STATUS)].includes(eventStatus)) {
        throw new Error('Invalid event status provided!');
      }
      queryStringValue = eventStatus;
    }
    const urlParam = companyId ? `/${companyId}` : '';
    const queryString = queryStringValue ? `?status=${queryStringValue}` : '';
    const axiosResponse = await api.get(`/event/company${urlParam}${queryString}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getCompanyEvents', axiosError);
  }
};

export const getPartnerWaitingEvents = async (radius = 50) => {
  try {
    const axiosResponse = await api.get(`/event/partner/available?radius=${radius}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getPartnerWaitingEvents', axiosError);
  }
};

export const getPartnerScheduledEvents = async () => {
  try {
    const axiosResponse = await api.get('/event/partner/scheduled');
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getPartnerScheduledEvents', axiosError);
  }
};

export const getPartnerPastEvents = async () => {
  try {
    const axiosResponse = await api.get('/event/partner/past');
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getPartnerPastEvents', axiosError);
  }
};

/**
 * Update an event
 * @param {string} eventId The event id to update
 * @param {Object} data All data to update
 * @returns
 */
export const requestUpdateEvent = async (eventId, data) => {
  if (!eventId) {
    throw new Error('Event id must be specified to update it');
  }
  try {
    const axiosResponse = await api.post(`/event/update/${eventId}`, data);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('requestUpdateEvent', axiosError);
  }
};

/**
 * Update appointments in bulk
 * @param {Object} data All data to update
 * @returns
 */
export const requestAttendanceStatusBulkUpdate = async (data) => {
  if (!data) {
    throw new Error('No data provided');
  }
  try {
    const axiosResponse = await api.put(`/event/update-attendance-status`, data);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('requestAttendanceStatusBulkUpdate', axiosError);
  }
};

/**
 * Get an event by id
 * @param {string} eventId The event id to update
 * @returns {Promise<Object>}
 */
export const getEvent = async (eventId) => {
  if (!eventId) {
    throw new Error('Event id must be specified');
  }
  try {
    const axiosResponse = await api.get(`/event/${eventId}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getEvent', axiosError);
  }
};

export const deleteEvent = async (eventId) => {
  if (!eventId) {
    throw new Error('Event id must be specified');
  }
  try {
    const axiosResponse = await api.delete(`/event/${eventId}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('deleteEvent', axiosError);
  }
};

/**
 * API request to replace the partner on given event
 * @param {string} eventId - event id
 * @param {string} practitionerId - id of new practitioner
 * @param {string} [providerId] - Id of new provider if practitioner is of referent type
 * @returns {Promise<Object>}
 */
export const replaceEventPartner = async (eventId, practitionerId, providerId) => {
  if (!eventId) {
    throw new Error('Event id must be specified');
  }
  if (!practitionerId) {
    throw new Error('Practitioner id must be specified');
  }
  try {
    const axiosResponse = await api.put(`/event/${eventId}/replace`, {
      practitioner: practitionerId,
      provider: providerId
    });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('replaceEventPartner', axiosError);
  }
};

export const createEvent = async (eventData) => {
  if (!eventData) {
    throw new Error('No event data provided');
  }
  try {
    const axiosResponse = await api.post('/event/create', JSON.stringify(eventData));
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('createEvent', axiosError);
  }
};

/**
 * Book or Unbook an event
 * @param {string} eventId The event ID
 * @param {'book'|'unbook'} action The action to be applied (book or unbook)
 * @param {Object| null} slot The slot to update if unbook an appointment
 * @param {Object} data Additional data object, can contain:
 * @param {boolean} data.groupSessionEvent - true if event is of group session type
 * @param {string} data.reason if case of unbook action reason why appointment is canceled
 * @param {string} data.employeeId The employee id to unbook when requested by practitioner
 * @param {Object} data.newSlot slot details in case of booking move action
 * @returns {Promise<Object>}
 */
export const requestUpdateBooking = async (eventId, action, slot, data) => {
  const { reason, employeeId, newSlot, groupSessionEvent = false } = data;
  if (!eventId) {
    throw new Error('missing_event_id');
  }
  if (!action) {
    throw new Error('missing_event_action');
  }
  if (action !== 'book' && action !== 'unbook' && action !== 'move') {
    throw new Error('invalid_event_action');
  }
  if (!groupSessionEvent && !slot) {
    throw new Error('missing_event_slot');
  }
  if (action === 'unbook' && (isNaN(reason) || reason < 0 || reason > MAX_REASON_VALUE)) {
    throw new Error('invalid_unbook_reason');
  }
  if (action === 'move' && newSlot === undefined) {
    throw new Error('invalid_move_slot');
  }
  try {
    const axiosResponse = await api.put(`/event/${eventId}/update-booking`, {
      action,
      slot,
      ...(action === 'unbook' && { reason }),
      ...(action === 'move' && { newSlot }),
      employeeId
    });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('requestUpdateBooking', axiosError);
  }
};

/**
 * Update an event slot reservation (reserved flag)
 * @param {string} eventId The event id
 * @param {Object[]} slots Slots to update
 * @returns {Promise<Object>}
 */
export const requestUpdateReservation = async (eventId, slots) => {
  if (!eventId) {
    throw new Error('missing_event_id');
  }
  if (!slots) {
    throw new Error('missing_event_slot');
  }
  try {
    const axiosResponse = await api.put(`/event/${eventId}/update-reservation`, {
      slots
    });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('requestUpdateReservation', axiosError);
  }
};

/**
 * Updates an event beneficiary registration status
 * either beneficiary appointment status or attendee presence status
 * @param {Object} event - The event to update
 * @param {string} beneficiaryId - beneficiary Id
 * @param {string} status - beneficiary registration status
 * @param {Object} [appointment] - The appointment to update if applicable
 * @returns {Promise<Object>}
 */
export const requestUpdateRegistrationStatus = async (event, beneficiaryId, status, appointment = null) => {
  const { _id: eventId, groupSessionEvent } = event ?? {};
  if (!event || !eventId) {
    throw new Error('missing_event_data');
  }
  if (!beneficiaryId) {
    throw new Error('missing_beneficiary_id');
  }
  if (!status) {
    throw new Error('missing_registration_status');
  }
  const validStatuses = groupSessionEvent ? Object.keys(AttendeeStatus) : Object.keys(AppointmentStatus);
  if (!validStatuses.includes(status)) {
    console.log(`requestUpdateRegistrationStatus: invalid status: ${status}`);
    throw new Error('invalid_registration_status');
  }
  if (!groupSessionEvent && !appointment) {
    throw new Error('missing_event_appointment');
  }
  try {
    const axiosResponse = await api.put(`/event/${eventId}/update-registration-status`, {
      status,
      beneficiaryId,
      appointment
    });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('requestUpdateRegistrationStatus', axiosError);
  }
};

/**
 * Update an event appointment revenue
 * @param {string} eventId The event id
 * @param {string} revenue Appointment revenue
 * @param {Object} appointment The appointment to update
 * @returns {Promise<Object>}
 */
export const requestUpdateAppointmentRevenue = async (eventId, revenue, appointment) => {
  if (!eventId) {
    throw new Error('missing_event_id');
  }
  if (revenue == null) {
    throw new Error('missing_event_revenue');
  }
  if (!appointment) {
    throw new Error('missing_event_appointment');
  }
  try {
    const axiosResponse = await api.put(`/event/${eventId}/update-appointment-revenue`, {
      revenue,
      appointment
    });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('requestUpdateAppointmentRevenue', axiosError);
  }
};

export const unregisterEvent = async (eventId, partnerId) => {
  if (!eventId) {
    throw new Error('unregister_event_missing_event_id');
  }
  if (!partnerId) {
    throw new Error('unregister_event_missing_partner_id');
  }
  try {
    const axiosResponse = await api.delete(`event/${eventId}/partner/${partnerId}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('unregisterEvent', axiosError);
  }
};

export const updateUserByIdRequest = async (userId, data) => {
  if (!userId) {
    throw new Error('update_user_missing_user_id');
  }
  if (!data) {
    throw new Error('update_user_missing_data');
  }
  try {
    const axiosResponse = await api.put(`/users/${userId}`, data);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('updateUser', axiosError);
  }
};

export const getUser = async (userId) => {
  if (!userId) {
    throw new Error('User id must be specified');
  }
  try {
    const axiosResponse = await api.get(`/users/${userId}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getUser', axiosError);
  }
};

export const getCurrentUser = async () => {
  try {
    if (!getAuthToken()) {
      //Avoid an unnecessary request to server with 401
      return null;
    }
    const axiosResponse = await api.get('/users/me');
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getCurrentUser', axiosError);
  }
};

export const getAppInfoConfig = async () => {
  try {
    const axiosResponse = await api.get(`/config/app-info?cachebust=${Date.now()}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getAppInfoConfig', axiosError);
  }
};

export const sendSponsorInvitations = async (invitationList) => {
  if (!invitationList || invitationList.length < 1) {
    throw new Error('Not invited users specified');
  }
  try {
    return await api.post('/users/send-sponsor', {
      ...invitationList
    });
  } catch (axiosError) {
    logAndThrowAxiosError('sendSponsorInvitations', axiosError);
  }
};

/**
 * Registers a user on a event (JDS)
 * user role is either practitioner or speaker
 * if practitioner, data object contains:
 *  {Object[]} [slots] - Event slots
 *  {string} [providerId] - Id of the provider, can be empty
 * @param {string} userId - Id of the user to register
 * @param {object} [data={}] - registration data (only required for practitioners)
 * @returns {Promise<Object>} The event object modified
 */
export const registerEvent = async (userId, data = {}) => {
  try {
    const axiosResponse = await api.post(`/event/register-partner/${userId}`, data);
    const res = formatAxiosResponse(axiosResponse);
    console.log('registerEvent res=', res);
    return res;
  } catch (axiosError) {
    logAndThrowAxiosError('registerEvent', axiosError);
  }
};

/**
 * Add referent providers listed in newProviders array
 * @param {Object[]} newProviders Data of the user(s) to add
 * @returns {Promise<Object>} API response data including status code
 */
export const addNetWorkProviders = async (newProviders) => {
  try {
    if (!newProviders) {
      throw new Error('api.js/addNetWorkProviders | No providers data provided');
    }
    const payload = { providers: newProviders };
    if (payloadTooLarge(payload, 'api.js/addNetWorkProviders')) {
      throw new Error('request entity too large');
    }
    const axiosResponse = await api.post(`/users/add-network-providers`, payload, { timeout: LONG_REQUEST_TIMEOUT });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('addNetWorkProviders', axiosError);
  }
};

/**
 * Send an email to an employee
 * @param {string} employeeId - Id of the user the mail is sent to
 * @param {string} eventId - The event id the email is attached to
 * @param {string} message - The body of the email sent to the user
 * @param {array} fileList - The list of files attached to the mail
 * @returns {Promise} True if the message has been send
 */
export const sendMessageToBeneficiaries = async (beneficiaryIds, eventId, message, fileList) => {
  try {
    if (!beneficiaryIds) {
      throw new Error('api.js/sendMessageToAppointmentEmployee | No beneficiary Ids provided');
    }
    if (!eventId) {
      throw new Error('api.js/sendMessageToAppointmentEmployee | No eventId provided');
    }
    if (!message) {
      throw new Error('api.js/sendMessageToAppointmentEmployee | No message provided');
    }
    const formData = new FormData();
    fileList?.forEach((file) => {
      formData.append('attachements', file, file.name);
    });

    formData.append('beneficiaryIds', JSON.stringify(beneficiaryIds));
    formData.append('message', message);
    const axiosResponse = await api.post(`/event/${eventId}/send-message`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('sendMessageToAppointmentEmployee', axiosError);
  }
};

/**
 * creates practitioners accounts from list
 * @param {Object[]} practitioners Data of practitioners to add
 * @param {string} specialty Id of practitioner specialty
 * @param {string} entityName New practitioners' entity name
 * @param {string} inviterIdentity Identity of the inviter, only for display in email
 * @returns {Promise<Object>} API return data + status
 */
export const addPractitioners = async (practitioners, specialty, entityName, inviterIdentity) => {
  try {
    if (!practitioners) {
      throw new Error('No practitioner data provided');
    }
    const payload = {
      practitioners,
      specialty,
      entityName,
      inviterIdentity
    };
    if (payloadTooLarge(payload, 'api.js/addPractitioners')) {
      throw new Error('request entity too large');
    }
    const axiosResponse = await api.post(`/users/invite-practitioner-list`, payload, { timeout: LONG_REQUEST_TIMEOUT });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('addPractitioners', axiosError);
  }
};

/**
 * Sign in the user with email and password
 * @param {string} email - user email
 * @param {string} password - user password
 * @param {string} referer - signin page source or '' if undefined
 * if source value is Viabeez indicates signin page loaded from VB web site
 * @returns {Promise<Object>}
 */
export const signInUser = async (email, password, referer = '') => {
  try {
    if (!email || !password) {
      throw new Error(`Missing sign in data: email=${email}, password${password}`);
    }
    isSignOutInProgress = false;
    const axiosResponse = await api.post('/auth/signin', {
      email,
      password,
      referer
    });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('signInUser', axiosError);
  }
};

export const signOutUser = async () => {
  try {
    if (!isSignOutInProgress && getAuthToken()) {
      isSignOutInProgress = true;
      console.log('Calling sign out API...');
      // sign out of user (expected to be auth when calling this)
      const axiosResponse = await api.get('/auth/signout');
      return formatAxiosResponse(axiosResponse);
    }
  } catch (axiosError) {
    logAndThrowAxiosError('signOutUser', axiosError);
  }
};

/**
 * Signup of a practitioner user
 * @param {Object} formData practitioner's data
 * @returns {Promise}
 */
export const signupPractitioner = async (formData, isReferent = false) => {
  try {
    if (!formData) {
      throw new Error('No data provided');
    }
    const onBoardingCompleted = isReferent; // for referent onBoarding completed as soon as account created
    formData = { ...formData, onBoardingCompleted };
    const axiosResponse = await api.post('/auth/signup-practitioner', formData);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('signupPractitioner', axiosError);
  }
};

/**
 * Signup of a speaker user
 * @param {Object} formData speaker user data
 * @returns {Promise}
 */
export const signupSpeaker = async (formData) => {
  try {
    if (!formData) {
      throw new Error('No data provided');
    }
    const axiosResponse = await api.post('/auth/signup-speaker', formData);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('signupSpeaker', axiosError);
  }
};

/**
 * Complete signup of a practitioner imported by Viabeez
 * @param {string} practitionerId practitioner's id
 * @param {Object} formData practitioner's data
 * @returns {Promise}
 */
export const completePractitionerSignup = async (practitionerId, formData) => {
  try {
    if (!practitionerId) {
      throw new Error('No practitionerId provided');
    }
    if (!formData) {
      throw new Error('No data provided');
    }
    const axiosResponse = await api.put(`/auth/complete-practitioner-signup/${practitionerId}`, formData);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('completePractitionerSignup', axiosError);
  }
};

/**
 * Mark practitioner onboarding process as complete
 * @param {string} practitionerId practitioner's id
 * @returns {Promise}
 */
export const completePractitionerOnboarding = async (practitionerId) => {
  try {
    if (!practitionerId) {
      throw new Error('No practitionerId provided');
    }
    const axiosResponse = await api.put(`/auth/complete-practitioner-onboarding/${practitionerId}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('completePractitionerOnboarding', axiosError);
  }
};

/**
 * Validate a user email using the token from email
 * @param {string} validationToken The token from email
 * @returns {Promise<Object>} Validated user
 */
export const validateEmail = async (validationToken) => {
  try {
    if (!validationToken) {
      throw new Error('api.js/validateEmail - Validation token not provided');
    }
    const axiosResponse = await api.post(`/auth/validate-email`, {
      validationToken
    });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('validateEmail', axiosError);
  }
};

/**
 * Get a user associated to a validation token
 * @param {string} token The token to validate
 * @returns {Promise<Object>} The user associated to the token | error if token invalid
 */
export const getUserFromToken = async (token) => {
  try {
    if (!token) {
      throw new Error('No Token provided');
    }
    const axiosResponse = await api.get(`/users/user-token/${token}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getUserFromToken', axiosError);
  }
};

export const sendEventReminderToUnsubscribed = async (eventId) => {
  try {
    if (!eventId) {
      throw new Error('missing_event_id');
    }
    const axiosResponse = await api.post('/event/send-reminder', { eventId });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    const { response } = axiosError;
    const {
      status,
      data: { errors }
    } = response ?? {};
    if (status === 425) {
      // Too early response, in this case error message contains min delay duration (in days)
      return { status: 'unauthorized', delay: errors?.message };
    }
    logAndThrowAxiosError('sendEventReminderToUnsubscribed', axiosError);
  }
};

export const toggleEventNoLongerFullSubscription = async (eventId, sendNotification) => {
  try {
    if (!eventId) {
      throw new Error('missing_event_id');
    }
    const axiosResponse = await api.put(`/event/${eventId}/update-not-full-subscription`, { sendNotification });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('toggleEventNoLongerFullSubscription', axiosError);
  }
};

/**
 * Delete a company
 * @param {string} companyId The company ID
 * @returns {Promise<void>}
 */
export const requestDeleteCompany = async (companyId) => {
  try {
    if (!companyId) {
      throw new Error('missing_company_id');
    }
    const axiosResponse = await api.delete(`/companies/${companyId}`, { timeout: LONG_REQUEST_TIMEOUT });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('requestDeleteCompany', axiosError);
  }
};

/**
 * performs user deletion API request
 * request payload indicates if user deleted because seen as a duplicate or not
 * user account is only deleted if not validated or no activity
 * otherwise it is set to 'unsubscribed' status if isDeletedAsDuplicate is false
 * or account is set to 'duplicate' status if isDeletedAsDuplicate is true
 * with 'unsubscribed' or 'duplicate' status account data are still present but
 * user can't login anymore
 * Note : isDeletedAsDuplicate expected to be true only for beneficiary users
 * @param {string} userId - The Id of user to delete
 * @param {boolean} [isDeletedAsDuplicate=false] - if true indicates that this user was detected as a duplicate of another user account
 * @returns {Promise<Object>}
 */
export const requestDeleteUser = async (userId, isDeletedAsDuplicate = false) => {
  try {
    if (!userId) {
      throw new Error('missing_user_id');
    }
    const url = `/users/${userId}${isDeletedAsDuplicate ? '?dup=1' : ''}`;
    const axiosResponse = await api.delete(url);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('requestDeleteUser', axiosError);
  }
};

/**
 * Queries the list of sites the admin defined by Id is administrator for
 * returns an array of {id, name} tuples
 * @param {string} userId  Id of admin to make query for
 * @param {boolean} [includeMainCompany]  include all parents sites
 * @returns {Promise<Object>} a list of sites. Each site has id and name properties
 */
export const getAdminSites = async (userId, includeMainCompany = false) => {
  try {
    if (!userId) {
      throw new Error('missing_user_id');
    }
    const axiosResponse = await api.get(`/companies/admin-sites/${userId}`, { params: { includeMainCompany } });
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getAdminSites', axiosError);
  }
};

/**
 * Query the database to retrieve company data based on the given code
 * @param {string} companyCode a code with the LLL-LLL-NN (L: Letter, N: Number ) format
 * @returns {Promise<Object>} Company data
 */
export const getCompanyWithCode = async (companyCode) => {
  try {
    if (!companyCode) {
      throw new Error('missing_company_code');
    }
    const axiosResponse = await api.get(`/companies/code/${companyCode}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getCompanyWithCode', axiosError);
  }
};

/**
 * Fetches the company health audit status
 * @param {string} id - company id
 * @returns {Promise<Object>} - the company health audit survey state
 */
export const getHealthAuditState = async (id) => {
  try {
    const axiosResponse = await api.get(`/survey/health-survey-status/${id}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getHealthAuditState', axiosError);
  }
};

/**
 * SignUp for users that have a company code
 * @param {Object} formData input data provided by the user in order to create his account
 * @param {string} companyCode a code with the LLL-LLL-NN (L: Letter, N: Number ) format
 * @returns the newly created user
 */
export const signUpBeneficiaryByCode = async (formData, companyCode) => {
  try {
    if (!formData) {
      throw new Error('missing_form_data');
    }
    if (!companyCode) {
      throw new Error('missing_company_code');
    }
    const axiosResponse = await api.post(`/users/code-member/${companyCode}`, formData);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('signUpBeneficiaryByCode', axiosError);
  }
};

/**
 * Sets the onboardingCompleted boolean props true for the given user ID
 * Note: this API can also be called for a user that has admin-company role
 * as a company admin is also a beneficiary
 * @param {string} userId - mongoDB format user id
 * @returns {Object} API response
 */
export const completeBeneficiaryOnboarding = async (userId) => {
  try {
    if (!userId) {
      throw new Error('missing_beneficiary_id');
    }
    const axiosResponse = await api.put(`/auth/complete-company-member-onboarding/${userId}`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('completeBeneficiaryOnboarding', axiosError);
  }
};

/**
 * builds user feedback message for invitation email sending action
 * feedback message depends on type of user
 * @param {Object} response - API response
 * @param {function} t - localization function
 * @param {0|1|2} userType - invitation sent for: beneficiary=0, practitioner=1, provider=2
 * @returns {object} the feedback message object for display
 */
export const invitationEmailReqFeedback = (response, t, userType = 0) => {
  const feedback = {};
  let successMsgId = 'invitation.sent.beneficiary.success.message';
  switch (userType) {
    case 1:
      successMsgId = 'invitation.sent.practitioner.success.message';
      break;
    case 2:
      successMsgId = 'invitation.sent.provider.success.message';
      break;
    default:
  }
  if (response) {
    if (response.success) {
      feedback.status = 'success';
      feedback.title = t('invitation.sent.success.title', { count: response.emailSent });
      feedback.message = t(successMsgId, { count: response.emailSent });
      if (response.emailFailed) {
        feedback.failed = t('invitation.failed.email', { count: response.emailFailed });
      }
    } else {
      feedback.status = 'error';
      feedback.title = t('invitation.sent.failure.title', { count: response.emailFailed });
      feedback.message = '';
    }
  }
  return feedback;
};

export const addEventFeedback = async (eventId, feedback) => {
  try {
    if (!eventId) {
      throw new Error('missing_event_id');
    }
    if (!feedback) {
      throw new Error('missing_feedback_data');
    }
    await api.put(`/event/${eventId}/add-event-feedback`, feedback);
  } catch (axiosError) {
    logAndThrowAxiosError('addEventFeedback', axiosError);
  }
};

export const getUserFeedbackData = async (userId) => {
  try {
    if (!userId) {
      throw new Error('missing_user_id');
    }
    const axiosResponse = await api.get(`/users/${userId}/get-event-feedback`);
    return formatAxiosResponse(axiosResponse);
  } catch (axiosError) {
    logAndThrowAxiosError('getUserFeedbackData', axiosError);
  }
};
