import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { faCircleEuro } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo, faFilePdf, faPeopleLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Box, ResponsiveContext } from 'grommet';
import { ToolTip } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';
import { computeDocumentUrl } from 'src/utility/documentUtils';
import { hasDocument, isOutOfBundlePricingSpecialty } from '../../features/specialties/specialtiesUtils';

/**
 * if specialty has a description text defined, render an icon that will display on hover the
 * description text
 * @param {Object} props - component properties
 * @param {Object} props.event - event to check
 * @param {string} props.iconColor - icon color
 * @param {string} [props.maxWidth='none'] - tooltip drop container max width
 * @returns {ReactFragment} - Component displaying the specialty description icon if applicable
 */
export const GroupSessionEventIcon = ({ event, iconColor, maxWidth = 'none' }) => {
  const responsiveSize = useContext(ResponsiveContext);
  const { groupSessionEvent } = event ?? {};
  const { t } = useTranslation();
  return (
    <>
      {groupSessionEvent && (
        <Box width="20px">
          <ToolTip
            placement="bottom"
            backgroundColor={THEME_COLORS.booked}
            dropStyle={{
              maxWidth,
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
              border: '1px solid rgba(0, 0, 0, 0.33)',
              textAlign: 'justify'
            }}
            textSize="14px"
            content={t('event.group.session.tip.label')}
            disabled={responsiveSize === 'small' ? true : false}
          >
            <FontAwesomeIcon icon={faPeopleLine} color={iconColor} size="sm" />
          </ToolTip>
        </Box>
      )}
    </>
  );
};

/**
 * if specialty has a description text defined, render an icon that will display on hover the
 * description text
 * @param {Object} props - component properties
 * @param {Object} props.specialty - specialty to check
 * @param {string} props.iconColor - icon color
 * @param {string} [props.maxWidth='none'] - tooltip drop container max width
 * @returns {ReactFragment} - Component displaying the specialty description icon if applicable
 */
export const SpecialtyDescriptionIcon = ({ specialty, iconColor, maxWidth = 'none' }) => {
  const responsiveSize = useContext(ResponsiveContext);
  const { descriptionText = '' } = specialty;
  return (
    <>
      {descriptionText !== '' && (
        <Box width="20px">
          <ToolTip
            placement="bottom"
            backgroundColor={THEME_COLORS.booked}
            dropStyle={{
              maxWidth,
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
              border: '1px solid rgba(0, 0, 0, 0.33)',
              textAlign: 'justify'
            }}
            textSize="14px"
            content={descriptionText}
            disabled={responsiveSize === 'small' ? true : false}
          >
            <FontAwesomeIcon icon={faCircleInfo} color={iconColor} size="sm" />
          </ToolTip>
        </Box>
      )}
    </>
  );
};

/**
 * if specialty has a document file defined, render an icon that will open/display the file
 * on click
 * @param {Object} props - component properties
 * @param {Object} props.specialty - specialty to check
 * @param {string} props.iconColor - icon color
 * @param {function} [props.onClick=()=>{}] - user defined callback when icon is clicked
 * @returns {ReactFragment} - Component displaying the specialty document icon if applicable
 */
export const SpecialtyDocumentIcon = ({ specialty, iconColor, onClick = () => {} }) => {
  const { t } = useTranslation();
  const appInfo = useSelector((state) => state?.auth?.appInfo || {});
  return (
    <>
      {hasDocument(specialty) && (
        <Box width="20px">
          <Anchor
            href={computeDocumentUrl(appInfo, `specialties/${specialty.documentFileName}`)}
            target="_blank"
            title={t('click.see.specialty.document')}
            onClick={onClick}
          >
            <FontAwesomeIcon icon={faFilePdf} color={iconColor} size="sm" />
          </Anchor>
        </Box>
      )}
    </>
  );
};

/**
 * if specialty requires extra billing, render an icon to inform company admin
 * @param {Object} props - component properties
 * @param {Object} props.specialty - specialty to check
 * @param {string} props.iconColor - icon color
 * @returns {ReactFragment} - Component displaying the extra billing icon if applicable
 */
export const OutOfBundleSpecialtyIcon = ({ specialty, iconColor = THEME_COLORS.extraBilling }) => {
  const { t } = useTranslation();
  const responsiveSize = useContext(ResponsiveContext);
  return (
    <>
      {isOutOfBundlePricingSpecialty(specialty) && (
        <Box width="25px">
          <ToolTip
            placement="bottom"
            backgroundColor={THEME_COLORS.booked}
            dropStyle={{
              maxWidth: '150px',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
              border: '1px solid rgba(0, 0, 0, 0.33)',
              textAlign: 'justify'
            }}
            textSize="14px"
            content={t('out.of.bundle.event.tooltip')}
            disabled={responsiveSize === 'small' ? true : false}
          >
            <Box animation={{ type: 'pulse', duration: 250 }}>
              <FontAwesomeIcon icon={faCircleEuro} color={iconColor} size="1x" />
            </Box>
          </ToolTip>
        </Box>
      )}
    </>
  );
};

/**
 * Adds icons to event card Header (pending or scheduled)
 * when appropriate
 * - one icon with tip to indicate that event is a group session event when applicable
 * - one icon to click to open/see event specialty description document if available
 * - one icon showing on hover event specialty description text if available
 * if no specialty document nor specialty description text no icons will display
 * @param {Object} props - component properties
 * @param {Object} props.event - event to display details for
 * @returns {ReactFragment} - Component displaying the event details icons if applicable
 */
const EventDetailsIcons = ({ event }) => {
  const { specialty } = event ?? {};
  return (
    <Box alignContent="end" direction="row" align="center">
      <OutOfBundleSpecialtyIcon specialty={specialty} />
      <SpecialtyDocumentIcon specialty={specialty} iconColor={THEME_COLORS.white} />
      <SpecialtyDescriptionIcon specialty={specialty} iconColor={THEME_COLORS.white} maxWidth="300px" />
      <GroupSessionEventIcon event={event} iconColor={THEME_COLORS.white} />
    </Box>
  );
};

export default EventDetailsIcons;
