import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Text, TextArea } from 'grommet';
import { ActionModal } from 'src/components';
import { THEME_COLORS } from '../../config/theme';

/**
 * when applicable adds a clickable link to display
 * event room requirements
 * @component
 * @param {Object} props Component props
 * @param {Object} props.event The event to process
 * @returns {Component} component to render room requirements info if applicable
 */
const RoomRequirements = ({ event }) => {
  const { t } = useTranslation();
  const [showRequirements, setShowRequirements] = useState(false);
  const { specialty = {} } = event ?? {};
  const { roomRequirements = '' } = specialty;

  return (
    <>
      <Box style={{ minHeight: '25px' }}>
        {roomRequirements !== '' && (
          <Anchor onClick={() => setShowRequirements(true)} style={{ textAlign: 'center', textDecoration: 'none' }}>
            <Text color={THEME_COLORS.orange} size="xsmall" weight={400} style={{ lineHeight: '25px' }}>
              {t('event.show.room.requirements.label')}
            </Text>
          </Anchor>
        )}
      </Box>
      {showRequirements && (
        <ActionModal
          modalPadding="none"
          noCancelButton
          modalWidth="550px"
          showModal={!!showRequirements}
          onHideModal={() => setShowRequirements()}
          title={t('event.show.requirements.modal.title')}
          confirmLabel={t('common.button.close')}
          onConfirm={() => setShowRequirements()}
        >
          <Box align="center" pad="xsmall" fill>
            <TextArea fill resize={false} rows={3} value={roomRequirements} size="small" readOnly={true} />
          </Box>
        </ActionModal>
      )}
    </>
  );
};

export default RoomRequirements;
